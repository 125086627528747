import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../models";

declare type ForbiddenPageProps = {
  role: UserRole;
};

export default function ForbiddenPage({
  role,
}: ForbiddenPageProps) {
  const navigate = useNavigate();

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, teal.400, teal.600)"
        backgroundClip="text">
        401
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Akses tidak diizinkan
      </Text>
      <Text color={'gray.500'} mb={6}>
        Anda tidak memiliki akses pada halaman ini.
      </Text>
      <Button
        colorScheme="teal"
        bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
        color="white"
        variant="solid"
        onClick={() => {
          if (!role) navigate("/login");
          else navigate(
            role === UserRole.ADMIN
              ? "/admin"
              : "/app"
          );
        }}
      >
        {!role ? "Masuk" : "Kembali ke Beranda"}
      </Button>
    </Box>
  );
}
