import { api, applyAuthHeaders } from "../helpers";
import { ApiResponse, IPesan, IRuangPesan } from "../models";

interface IPesanService {
  getRuangPesan(): Promise<ApiResponse<IRuangPesan[]>>;
  getAllPesan(id: string): Promise<ApiResponse<IPesan[]>>;
  create(userId?: number): Promise<ApiResponse<IRuangPesan>>;
  send(args: { userId?: number, pesan: string; }): Promise<ApiResponse<unknown>>;
}

export default function createPesanService(): IPesanService {
  return {
    async getRuangPesan() {
      try {
        const response = await api.get(
          "/pesan",
          {
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (err: any) {
        return {
          error: true,
          message: err.message ?? "Unkown Error",
        };
      }
    },

    async getAllPesan(id) {
      try {
        const response = await api.get(
          `/pesan/read/${id}`,
          {
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (err: any) {
        return {
          error: true,
          message: err.message ?? "Unkown Error",
        };
      }
    },

    async create(userId) {
      try {
        const response = await api.post(
          "/pesan/create",
          userId ? { userId } : null,
          {
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (err: any) {
        return {
          error: true,
          message: err.message ?? "Unkown Error",
        };
      }
    },

    async send(args) {
      try {
        const response = await api.post(
          "/pesan/send",
          args,
          {
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (err: any) {
        return {
          error: true,
          message: err.message ?? "Unkown Error",
        };
      }
    }
  };
}
