import { Card, Flex, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { IconType } from "react-icons";

declare type DashboardCardProps = {
  label: string;
  value?: string;
  Icon: IconType;
  iconSize?: number;
  onClick?: () => void;
};

export function DashboardCard({
  label,
  value,
  Icon,
  iconSize,
  onClick,
}: DashboardCardProps) {
  return (
    <Card
      onClick={onClick}
      _hover={onClick ? { opacity: 0.85, cursor: "pointer" } : {}}
      w={{ base: 300, md: 340 }}
      h={{ base: 160, md: 200 }}
      rounded={"xl"}
      shadow={"lg"}
      bgGradient={useColorModeValue("linear(to-tl, blackAlpha.100, whiteAlpha.300)", "linear(to-tl, blackAlpha.300, whiteAlpha.300)")}
      alignItems={"center"}
      justifyContent={"center"}
      gap={3}
      p={4}>
      <Text textAlign={"center"} fontSize={"lg"}>{label}</Text>
      <Flex alignItems={"center"} gap={2}>
        <Icon size={iconSize || 22} />
        <Heading hidden={!value} fontSize={{ base: "4xl", md: "6xl" }}>{value}</Heading>
      </Flex>
    </Card>
  );
}
