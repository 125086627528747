import { api, applyAuthHeaders } from "../helpers";
import { ApiResponse, GetAllRequest, ISkpi } from "../models";

interface ISkpiService {
  getAll(args: GetAllRequest<ISkpi>): Promise<ApiResponse<ISkpi[]>>;
  get(id: number): Promise<ApiResponse<ISkpi>>;
  post(data: ISkpi): Promise<ApiResponse<ISkpi>>;
  put(data: Partial<ISkpi>): Promise<ApiResponse<Partial<ISkpi>>>;
  delete(id: number): Promise<ApiResponse<any>>;
}

export default function createSkpiService(): ISkpiService {
  return {
    async getAll(args) {
      try {
        const response = await api.get(
          "/skpi",
          {
            params: {
              ...args,
              filter: encodeURIComponent(JSON.stringify(args.filter)),
            },
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async get(id) {
      try {
        const response = await api.get(
          "/skpi",
          {
            params: { id },
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async post(data) {
      try {
        const response = await api.post(
          "/skpi",
          data,
          { headers: applyAuthHeaders() }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async put(data) {
      try {
        const response = await api.put(
          "/skpi",
          data,
          { headers: applyAuthHeaders() }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async delete(id) {
      try {
        const response = await api.delete(
          "/skpi",
          {
            params: { id },
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
  };
}
