import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useBoolean,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { createUserService, createLampiranService, createSkpiService, createTranskripService } from "../../services";
import { useCheckToken } from "../../hooks";
import React from "react";
import { ILampiran, ISkpi, ITranskrip, IUser, SkpiGrade, UserRole } from "../../models";
import { ConfirmPrompt, Loading, PdfViewer, ProfileChecker } from "../../components";
import { FaEdit, FaExclamation, FaExternalLinkAlt, FaFilePdf, FaLink, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { isWajibFilled, parseSkpiGrade, toFormattedDate, toRomanString } from "../../helpers";
import { FiCheckSquare, FiPrinter } from "react-icons/fi";
import { useCetakSKPI } from "../../hooks/cetak";

export default function TranskripPage() {
  const [, user] = useCheckToken();
  const toast = useToast();
  const navigate = useNavigate();
  const userService = createUserService();
  const transkripService = createTranskripService();
  const lampiranService = createLampiranService();
  const skpiService = createSkpiService();
  const [userData, setuserData] = React.useState<IUser | null>(null);
  const [transkripData, settranskripData] = React.useState<ITranskrip[] | null>(null);
  const [skpiData, setskpiData] = React.useState<ISkpi[] | null>(null);
  const [selectedData, setselectedData] = React.useState<ITranskrip | null>(null);
  const [lampiran, setlampiran] = React.useState<ILampiran | null>(null);
  const [deleteModalOpen, deleteModal] = useBoolean();
  const [pengajuanModalOpen, pengajuanModal] = useBoolean();
  const [showPdfModal, pdfModal] = useBoolean();
  const approvedBg = useColorModeValue("green.200", "green.700");
  const tableSchema = useColorModeValue("blackAlpha", "gray");
  const { onCetak, isLoading: cetakLoading } = useCetakSKPI();

  async function fetchUser() {
    const result = await userService.get(user?.id!);
    setuserData(result.data);
  }

  async function fetchTranskrip() {
    const result = await transkripService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "no",
      order: "asc",
      filter: { user: { id: user?.id } },
    });
    settranskripData(result.data);
  }

  async function fetchSkpi() {
    const result = await skpiService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "no",
      order: "asc",
    });
    setskpiData(result.data);
  }

  async function onDelete(id: number) {
    const result = await transkripService.delete(id);
    if (!result.error) {
      await lampiranService.delete(selectedData?.lampiran.id!);
      fetchTranskrip();
      setselectedData(null);
      deleteModal.off();
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onAjukan() {
    const totalSkp = transkripData?.reduce((a, b) => a + b.prestasi.poin, 0) || 0;
    if (
      parseSkpiGrade(totalSkp) === SkpiGrade.D ||
      !isWajibFilled(transkripData)
    ) toast({
      title: "Gagal",
      description: parseSkpiGrade(totalSkp) === SkpiGrade.D
        ? "Total nilai SKP Anda belum cukup!"
        : "Pakarmaru Universitas dan Fakultas belum diisi!",
      status: "warning",
      duration: 3000,
    });
    else {
      const result = await userService.put({
        id: user?.id,
        diajukan: true,
      });
      if (!result.error) {
        fetchUser();
        fetchTranskrip();
        pengajuanModal.off();
      }
      toast({
        title: result.error ? "Gagal" : "Berhasil",
        description: result.message,
        status: result.error ? "error" : "success",
        duration: 3000,
      });
    }
  }

  React.useEffect(() => {
    fetchUser();
    fetchSkpi();
    fetchTranskrip();
    // eslint-disable-next-line
  }, []);

  if (!userData || !skpiData || !transkripData) return <Loading />;

  return (
    <ProfileChecker userData={userData}>
      <Box>
        <Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
          <Heading size={{ base: "sm", md: "md" }}>Rincian Transkrip SKPI Mahasiswa</Heading>
          <Flex gap={2} alignItems={"center"}>
            <Button
              hidden={userData.diajukan}
              onClick={pengajuanModal.on}
              size={{ base: "xs", md: "sm" }}
              colorScheme="green">
              <FiCheckSquare />
              <Text ml={2}>Ajukan pemeriksaan</Text>
            </Button>
            <Flex hidden={!userData.disetujui}>
              <Button
                onClick={() => onCetak(userData, skpiData)}
                isLoading={cetakLoading}
                colorScheme="green">
                <FiPrinter />
                <Text ml={2}>Cetak</Text>
              </Button>
            </Flex>
            <IconButton
              // hidden={userData.disetujui || userData.diajukan}
              hidden={userData.disetujui}
              colorScheme="teal"
              icon={<FaPlus />}
              size={{ base: "xs", md: "sm" }}
              aria-label="Tambah SKPI"
              onClick={() => navigate("tambah")} />
          </Flex>
        </Flex>
        {userData.disetujuiPada ? (
          <Box
            bg={approvedBg}
            rounded={"lg"}
            shadow={"lg"}
            my={5}
            p={4}>
            Transkrip SKPI Anda telah disetujui pada {toFormattedDate(userData.disetujuiPada)}
          </Box>
        ) : null}
        <TableContainer fontSize={{ base: "sm", md: "md" }}>
          <Table colorScheme={tableSchema}>
            <Thead>
              <Tr>
                <Th w={1}>#</Th>
                <Th w={1}>No.</Th>
                <Th>Nama Kegiatan</Th>
                <Th>Tingkat / Tempat</Th>
                <Th>Semester - Tahun</Th>
                <Th>Nilai SKP</Th>
                <Th>Bukti Fisik</Th>
                <Th hidden={userData.disetujui}>Status</Th>
                <Th hidden={userData.disetujui}>Aksi</Th>
              </Tr>
            </Thead>
            {skpiData.map((skpi) => (
              <React.Fragment key={skpi.id}>
                <Thead>
                  <Tr bgColor={"whiteAlpha.50"}>
                    <Th colSpan={2}>{skpi.no}</Th>
                    <Th colSpan={7}>{skpi.jenis} - {skpi.nama}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {transkripData.filter((x) => x.skpi.no === skpi.no).map((transkrip, index) => {
                    const startNum = transkripData.filter((t) => t.skpi.no < transkrip.skpi.no).length + index + 1;

                    return (
                      <Tr key={transkrip.id}>
                        <Td></Td>
                        <Td>{index + 1}</Td>
                        <Td>{transkrip.nama}</Td>
                        <Td>{transkrip.tingkat.nama}</Td>
                        <Td>{toRomanString(transkrip.semester)} - {transkrip.tahun}</Td>
                        <Td>{transkrip.prestasi.poin}</Td>
                        <Td>
                          <Flex gap={2}>
                            <Button
                              colorScheme="teal"
                              size={{ base: "xs", md: "sm" }}
                              onClick={() => {
                                navigate(
                                  `${user?.role === UserRole.ADMIN ? "/admin" : "/app"
                                  }/tabel-skpi/${transkrip.skpi.id
                                  }/${transkrip.kegiatan.id
                                  }/${transkrip.tingkat.id}`
                                );
                              }}>
                              <FaExternalLinkAlt />
                              <Text ml={2}>
                                {startNum} ({transkrip.skpi.no}-{transkrip.kegiatan.no})
                              </Text>
                            </Button>
                            {transkrip.lampiran ? (
                              <IconButton
                                colorScheme="green"
                                icon={transkrip.lampiran.path ? <FaFilePdf /> : <FaLink />}
                                size={{ base: "xs", md: "sm" }}
                                aria-label="lihat lampiran"
                                onClick={() => {
                                  if (transkrip.lampiran.path) {
                                    setlampiran(transkrip.lampiran);
                                    pdfModal.on();
                                  }
                                  else window.open(transkrip.lampiran.url, "_blank");
                                }} />
                            ) : <FaExclamation color="red" />}
                          </Flex>
                        </Td>
                        <Td hidden={userData.disetujui}>
                          {!userData.diajukan ? (
                            <Badge colorScheme="orange">Belum diajukan</Badge>
                          ) : (
                            <Flex gap={2} alignItems={"center"}>
                              <Badge colorScheme={
                                transkrip.disetujui
                                  ? "green"
                                  : transkrip.alasanPenolakan
                                    ? "red"
                                    : "yellow"}>
                                {transkrip.disetujui
                                  ? "Disetujui"
                                  : transkrip.alasanPenolakan
                                    ? "Ditolak"
                                    : "Belum ditanggapi"}
                              </Badge>
                              <Box
                                hidden={!transkrip.alasanPenolakan}
                                _after={{
                                  content: `'${transkrip.alasanPenolakan || ''}'`,
                                }}>
                              </Box>
                            </Flex>
                          )}
                        </Td>
                        <Td hidden={userData.disetujui || (userData.diajukan && !transkrip.alasanPenolakan)}>
                          <Flex gap={2}>
                            <IconButton
                              colorScheme="orange"
                              icon={<FaEdit />}
                              size={{ base: "xs", md: "sm" }}
                              aria-label="Edit SKPI"
                              onClick={() => {
                                navigate("edit", { state: { oldTranskrip: transkrip } });
                              }} />
                            <IconButton
                              colorScheme="red"
                              icon={<FaTrash />}
                              size={{ base: "xs", md: "sm" }}
                              aria-label="Hapus SKPI"
                              onClick={() => {
                                setselectedData(transkrip);
                                deleteModal.on();
                              }} />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                  <Tr>
                    <Td></Td>
                    <Td></Td>
                    <Td textAlign={"center"} colSpan={3} fontWeight={"semibold"}>Jumlah</Td>
                    <Td colSpan={4} fontWeight={"semibold"}>
                      {transkripData.filter((x) => x.skpi.no === skpi.no).reduce((a, b) => a + b.prestasi.poin, 0) || 0}
                    </Td>
                  </Tr>
                </Tbody>
              </React.Fragment>
            ))}
            <Tfoot>
              <Tr>
                <Td></Td>
                <Td></Td>
                <Td textAlign={"center"} colSpan={3} fontWeight={"bold"}>Jumlah Total</Td>
                <Td colSpan={4} fontWeight={"bold"}>
                  {transkripData.reduce((a, b) => a + b.prestasi.poin, 0) || 0}
                </Td>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
        <Flex gap={2} my={4}>
          <Text>Predikat SKPI Anda saat ini: </Text>
          <Badge
            colorScheme="teal"
            fontSize={"md"}>
            {parseSkpiGrade(transkripData.reduce((a, b) => a + b.prestasi.poin, 0) || 0)}
          </Badge>
        </Flex>
        <ConfirmPrompt
          title="Yakin hapus data transkrip pada baris ini?"
          description="Setelah data ini dihapus, lampiran yang terkait akan ikut terhapus secara permanen."
          isOpen={deleteModalOpen}
          onApprove={() => onDelete(selectedData?.id!)}
          onReject={() => {
            deleteModal.off();
            setselectedData(null);
          }} />
        <ConfirmPrompt
          title="Yakin mengajukan pemeriksaan transkrip SKPI Anda?"
          description={"Setelah mengajukan, Anda hanya dapat mengedit data yang ditolak/revisi."}
          isOpen={pengajuanModalOpen}
          onApprove={onAjukan}
          onReject={() => {
            pengajuanModal.off();
          }}
        />
        <PdfViewer
          lampiran={lampiran}
          isOpen={showPdfModal}
          onClose={pdfModal.off}
        />
      </Box>
    </ProfileChecker>
  );
}
