import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  ListItem,
  Text,
  Textarea,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { Select } from "chakra-react-select";
import React from "react";
import { ApiResponse, IBukti, IKegiatan, ILampiran, IPrestasi, ISkpi, ITingkat, ITranskrip, IUser } from "../../models";
import {
  createBuktiService,
  createKegiatanService,
  createLampiranService,
  createPrestasiService,
  createSkpiService,
  createTingkatService,
  createTranskripService,
  createUserService,
} from "../../services";
import { BackButton, FileSelector, Loading, ProfileChecker } from "../../components";
import { FaCheckCircle, FaFilePdf } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";

export default function EditSkpiPage() {
  const state = useLocation().state;
  const oldTranskrip: ITranskrip = state?.oldTranskrip || null;
  const toast = useToast();
  const navigate = useNavigate();
  const userService = createUserService();
  const skpiService = createSkpiService();
  const kegiatanService = createKegiatanService();
  const tingkatService = createTingkatService();
  const prestasiService = createPrestasiService();
  const buktiService = createBuktiService();
  const lampiranService = createLampiranService();
  const transkripService = createTranskripService();
  const [userData, setuserData] = React.useState<IUser | null>(null);
  const [transkripData, settranskripData] = React.useState<ITranskrip[] | null>(null);
  const [skpiData, setskpiData] = React.useState<ISkpi[] | null>(null);
  const [kegiatanData, setkegiatanData] = React.useState<IKegiatan[] | null>(null);
  const [tingkatData, settingkatData] = React.useState<ITingkat[] | null>(null);
  const [prestasiData, setprestasiData] = React.useState<IPrestasi[] | null>(null);
  const [buktiData, setbuktiData] = React.useState<IBukti[] | null>(null);
  const [jenisLampiran, setjenisLampiran] = React.useState<string | undefined>();
  const [lampiran, setlampiran] = React.useState<File | null>(null);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ITranskrip>({
    defaultValues: oldTranskrip,
  });
  const prestasi = watch("prestasi");

  async function onSubmit(value: ITranskrip) {
    let uploadedLampiran: ApiResponse<ILampiran> | null = null;
    if (lampiran)
      uploadedLampiran = await lampiranService.upload(
        lampiran,
        value.lampiran?.url
      );
    const result = await transkripService.put(
      lampiran ? {
        ...value,
        alasanPenolakan: null,
        lampiran: uploadedLampiran?.data,
      } : {
        ...value,
        alasanPenolakan: null,
      }
    );
    if (!result.error) {
      if (lampiran) await lampiranService.delete(
        oldTranskrip?.lampiran.id!
      );
      navigate(-1 as any, { replace: true });
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function fetchUser() {
    const result = await userService.get(oldTranskrip.user.id!);
    setuserData(result.data);
  }

  async function fetchTranskrip() {
    const result = await transkripService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "no",
      order: "asc",
      filter: { user: { id: userData?.id } },
    });
    settranskripData(result.data);
  }

  async function fetchSkpi() {
    const result = await skpiService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "no",
      order: "asc",
    });
    setskpiData(result.data);
  }

  async function fetchKegiatan(parentId: number) {
    const result = await kegiatanService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "no",
      order: "asc",
      filter: { skpi: { id: parentId } },
    });
    setkegiatanData(result.data);
  }

  async function fetchTingkat(parentId: number) {
    const result = await tingkatService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "id",
      order: "asc",
      filter: { kegiatan: { id: parentId } },
    });
    settingkatData(result.data);
  }

  async function fetchPrestasi(parentId: number) {
    const result = await prestasiService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "id",
      order: "asc",
      filter: { tingkat: { id: parentId } },
    });
    setprestasiData(result.data);
  }

  async function fetchBukti(parentId: number) {
    const result = await buktiService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "id",
      order: "asc",
      filter: { prestasi: { id: parentId } },
    });
    setbuktiData(result.data);
  }

  React.useEffect(() => {
    if (!oldTranskrip) navigate(
      -1 as any,
      { replace: true }
    );
    else {
      fetchUser();
      setjenisLampiran(oldTranskrip.bukti.nama.toLowerCase());
      fetchTranskrip();
      fetchSkpi();
      control.register("user", {
        value: {
          id: oldTranskrip.user.id,
          nama: oldTranskrip.user.nama,
          username: oldTranskrip.user.username,
          role: oldTranskrip.user.role,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  if (!transkripData || !userData) return <Loading />;

  return (
    <ProfileChecker userData={userData}>
      <Box>
        <Flex alignItems={"center"} gap={3} mb={3}>
          <BackButton />
          <Heading size={{ base: "sm", md: "md" }}>
            Edit SKPI
          </Heading>
        </Flex>
        <Grid
          templateRows='repeat(1, 1fr)'
          templateColumns='repeat(2, 1fr)'
          gap={4}>
          <GridItem rowSpan={1} colSpan={{ base: 2, lg: 1 }}>
            <Input
              hidden={true}
              {...control.register("no", {
                value: oldTranskrip?.no,
              })} />
            <Text>No. {oldTranskrip?.no}</Text>
            <Flex gap={3}>
              <FormControl mt={3} isInvalid={!(!errors.semester)}>
                <FormLabel>Semester</FormLabel>
                <Input
                  type="number"
                  placeholder="Semester (cth: 4)"
                  {...control.register("semester", {
                    required: "Semester tidak boleh kosong",
                  })} />
                <FormErrorMessage>{errors.semester?.message}</FormErrorMessage>
              </FormControl>
              <FormControl mt={3} isInvalid={!(!errors.tahun)}>
                <FormLabel>Tahun kegiatan</FormLabel>
                <Input
                  type="number"
                  placeholder="Tahun kegiatan (cth: 2022)"
                  {...control.register("tahun", {
                    required: "Tahun kegiatan tidak boleh kosong",
                  })} />
                <FormErrorMessage>{errors.tahun?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
            <FormControl mt={3} isInvalid={!(!errors.nama)}>
              <FormLabel>Deskripsi Kegiatan</FormLabel>
              <Textarea
                placeholder="Deskripsi kegiatan (cth: Pakarmaru Universitas)"
                {...control.register("nama", {
                  required: "Deskripsi kegiatan tidak boleh kosong",
                })} />
              <FormErrorMessage>{errors.nama?.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={3} isInvalid={!(!errors.skpi)}>
              <FormLabel>Bidang kegiatan</FormLabel>
              <Controller
                control={control}
                name="skpi"
                rules={{
                  required: "Silahkan pilih bidang kegiatan terlebih dahulu",
                }}
                render={({ field }) => (
                  <Select
                    placeholder={skpiData ? "Pilih bidang kegiatan" : "Loading..."}
                    options={skpiData?.map((x) => ({
                      label: `${x.no}. [${x.jenis}] ${x.nama}`,
                      value: x,
                    }))}
                    defaultValue={{
                      label: `${oldTranskrip?.skpi.no}. [${oldTranskrip?.skpi.jenis}] ${oldTranskrip?.skpi.nama}`,
                      value: oldTranskrip?.skpi!,
                    }}
                    onChange={(val) => {
                      field.onChange(val?.value);
                      fetchKegiatan(val?.value.id!);
                      control.unregister("kegiatan");
                      control.unregister("tingkat");
                      control.unregister("prestasi");
                      control.unregister("bukti");
                    }}
                  />
                )}
              />
              <FormErrorMessage>{errors.skpi?.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={3} isInvalid={!(!errors.kegiatan)}>
              <FormLabel>Jenis kegiatan</FormLabel>
              <Controller
                control={control}
                name="kegiatan"
                rules={{
                  required: "Silahkan pilih jenis kegiatan terlebih dahulu",
                }}
                render={({ field }) => (
                  <Select
                    placeholder="Pilih jenis kegiatan"
                    options={kegiatanData?.map((x) => ({
                      label: `${x.no}. ${x.nama}`,
                      value: x,
                    }))}
                    defaultValue={{
                      label: `${oldTranskrip?.kegiatan.no}. ${oldTranskrip?.kegiatan.nama}`,
                      value: oldTranskrip?.kegiatan!,
                    }}
                    onChange={(val) => {
                      field.onChange(val?.value);
                      fetchTingkat(val?.value.id!);
                      control.unregister("tingkat");
                      control.unregister("prestasi");
                      control.unregister("bukti");
                    }}
                  />
                )}
              />
              <FormErrorMessage>{errors.kegiatan?.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={3} isInvalid={!(!errors.tingkat)}>
              <FormLabel>Tingkat</FormLabel>
              <Controller
                control={control}
                name="tingkat"
                rules={{
                  required: "Silahkan pilih tingkat terlebih dahulu",
                }}
                render={({ field }) => (
                  <Select
                    placeholder="Pilih tingkat"
                    options={tingkatData?.map((x) => ({
                      label: x.nama,
                      value: x,
                    }))}
                    defaultValue={{
                      label: oldTranskrip?.tingkat.nama,
                      value: oldTranskrip?.tingkat!,
                    }}
                    onChange={(val) => {
                      field.onChange(val?.value);
                      fetchPrestasi(val?.value.id!);
                      control.unregister("prestasi");
                      control.unregister("bukti");
                    }}
                  />
                )}
              />
              <FormErrorMessage>{errors.tingkat?.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={3} isInvalid={!(!errors.prestasi)}>
              <FormLabel>Prestasi / Jabatan / Partisipasi</FormLabel>
              <Controller
                control={control}
                name="prestasi"
                rules={{
                  required: "Silahkan pilih prestasi / Jabatan / Partisipasi terlebih dahulu",
                }}
                render={({ field }) => (
                  <Select
                    placeholder="Pilih prestasi / Jabatan / Partisipasi"
                    options={prestasiData?.map((x) => ({
                      label: x.nama,
                      value: x,
                    }))}
                    defaultValue={{
                      label: oldTranskrip?.prestasi.nama,
                      value: oldTranskrip?.prestasi!,
                    }}
                    onChange={(val) => {
                      field.onChange(val?.value);
                      fetchBukti(val?.value.id!);
                      control.unregister("bukti");
                    }}
                  />
                )}
              />
              <FormErrorMessage>{errors.prestasi?.message}</FormErrorMessage>
            </FormControl>
            <Badge mt={5} colorScheme="teal" fontSize={"md"} textTransform={"capitalize"}>
              Nilai SKP : {prestasi?.poin || "-"}
            </Badge>
          </GridItem>
          <GridItem
            colSpan={{ base: 2, lg: 1 }}
            height={"max"}
            border={"1px"}
            borderColor={"whiteAlpha.300"}
            borderRadius={"xl"}
            p={4}>
            <Flex
              alignItems={"center"}
              justifyContent={"center"}
              fontWeight={"semibold"}
              gap={2}>
              <FaFilePdf />
              Lampiran
            </Flex>
            <FormControl mt={3} isInvalid={!(!errors.bukti)}>
              <FormLabel>Jenis bukti fisik</FormLabel>
              <Controller
                control={control}
                name="bukti"
                rules={{
                  required: "Silahkan pilih jenis bukti sebelum unggah dokumen",
                }}
                render={({ field }) => (
                  <Select
                    placeholder="Pilih jenis bukti fisik"
                    options={buktiData?.map((x) => ({
                      label: x.nama,
                      value: x,
                    }))}
                    defaultValue={{
                      label: oldTranskrip?.bukti.nama,
                      value: oldTranskrip?.bukti!,
                    }}
                    onChange={(val) => {
                      field.onChange(val?.value);
                      setjenisLampiran(val?.label?.toLowerCase());
                    }}
                  />
                )}
              />
              <FormErrorMessage>{errors.bukti?.message}</FormErrorMessage>
            </FormControl>
            {jenisLampiran === "dok" ? (
              <FormControl mt={3} isInvalid={!(!errors.lampiran?.url) && jenisLampiran !== "dok"}>
                <FormLabel>Link dokumentasi</FormLabel>
                <Input
                  {...control.register("lampiran.url")}
                  placeholder="link dokumentasi (cth: https://youtube.com/)"
                />
              </FormControl>
            ) : (
              <FileSelector onSelected={(file) => setlampiran(file)} />
            )}
            <Flex
              justifyContent={"space-between"}
              borderBottom={"1px"}
              pb={1}>
              <Flex alignItems={"center"} gap={2}>
                <FaCheckCircle color="green" size={18} />
                <Text fontSize={"sm"}>{lampiran ? lampiran.name : oldTranskrip?.lampiran.nama}</Text>
              </Flex>
              <IconButton
                variant={"ghost"}
                size={"sm"}
                icon={<FiX />}
                aria-label="hapus berkas"
                onClick={() => setlampiran(null)} />
            </Flex>
            {jenisLampiran === "dok" ? (
              <UnorderedList fontSize={"sm"} mt={2}>
                <ListItem>Jika dokumentasi berupa foto / video lebih dari satu, harap digabung ke dalam 1 folder Google Drive / Dropbox / dst.</ListItem>
                <ListItem>Pastikan akses folder telah terbuka (can view)</ListItem>
              </UnorderedList>
            ) : (
              <UnorderedList fontSize={"sm"} mt={2}>
                <ListItem>Hanya dapat menerima 1 berkas dengan ekstensi PDF</ListItem>
                <ListItem>Jika lampiran lebih dari satu, harap digabung ke dalam 1 berkas PDF</ListItem>
                <ListItem>Ukuran berkas maksimal 1024 KB (1 MB)</ListItem>
              </UnorderedList>
            )}
          </GridItem>
        </Grid>
        <Button
          w={"100%"}
          mt={10}
          colorScheme="teal"
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}>
          Simpan
        </Button>
      </Box>
    </ProfileChecker>
  );
}
