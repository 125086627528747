import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { ChangeEventHandler } from "react";

declare type ConfirmPromptProps = {
  title: string;
  description: string;
  isOpen: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  onApprove: () => void;
  onReject: () => void;
};

export function ConfirmPrompt({
  title,
  description,
  isOpen,
  onChange,
  placeholder,
  onApprove,
  onReject,
}: ConfirmPromptProps) {
  return (
    <Modal isOpen={isOpen} onClose={onReject}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {description}
          {!onChange ? null : (
            <FormControl mt={4}>
              <Textarea placeholder={placeholder} onChange={onChange} />
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant={"outline"} mr={3} onClick={onReject}>
            Batal
          </Button>
          <Button colorScheme="red" onClick={onApprove}>Konfirmasi</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
