import { ChakraProvider, theme, useToast } from "@chakra-ui/react";
import { Loading, ProtectedLayout } from "./components";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { AdminPages, CommonPages, UserPages } from "./pages";
import { appStorage } from "./helpers";
import React from "react";
import { useCheckToken } from "./hooks";
import { UserRole } from "./models";

export default function App() {
  const [isTokenValid, user] = useCheckToken();
  const toast = useToast();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isTokenValid && appStorage.get("token") !== null) {
      appStorage.remove("token");
      toast({
        title: "Token tidak valid",
        description: "Token telah kedaluwarsa, silahkan masuk lagi",
        status: "warning",
        duration: 3000,
      });
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/">
            <Route index element={
              <Navigate to={
                isTokenValid
                  ? user?.role === UserRole.ADMIN
                    ? "/admin"
                    : "/app"
                  : "login"
              } />} />
            <Route path="login" element={<CommonPages.LoginPage />} />
            <Route path="forbidden" element={<CommonPages.ForbiddenPage role={user?.role!} />} />
          </Route>

          <Route path="/admin" element={<ProtectedLayout roles={[UserRole.ADMIN]} />}>
            <Route index element={<AdminPages.DashboardPage />} />
            <Route path="tabel-skpi">
              <Route index element={<CommonPages.TabelSkpiPage />} />
              <Route path=":skpiId" element={<CommonPages.TabelKegiatanPage />} />
              <Route path=":skpiId/:kegiatanId" element={<CommonPages.TabelTingkatPage />} />
              <Route path=":skpiId/:kegiatanId/:tingkatId" element={<CommonPages.TabelPrestasiPage />} />
            </Route>
            <Route path="pengajuan-skpi">
              <Route index element={<AdminPages.PengajuanSkpiPage />} />
              <Route path=":userId" element={<AdminPages.TranskripDetailPage />} />
              <Route path=":userId/edit" element={<AdminPages.EditSkpiPage />} />
            </Route>
            <Route path="riwayat">
              <Route index element={<AdminPages.RiwayatPage />} />
              <Route path=":userId" element={<AdminPages.TranskripDetailPage />} />
            </Route>
            <Route path="draft">
              <Route index element={<AdminPages.DraftPage />} />
              <Route path=":userId" element={<AdminPages.TranskripDetailPage />} />
            </Route>
            <Route path="pesan" element={<CommonPages.PesanPage />}>
              <Route path=":ruangPesanId" element={<CommonPages.PesanDetailPage />} />
            </Route>
            <Route path="pengaturan" element={<AdminPages.PengaturanPage />} />
            <Route path="himbauan" element={<AdminPages.HimbauanPage />} />
          </Route>

          <Route path="/app" element={<ProtectedLayout roles={[UserRole.MAHASISWA]} />}>
            <Route index element={<UserPages.DashboardPage />} />
            <Route path="tabel-skpi">
              <Route index element={<CommonPages.TabelSkpiPage />} />
              <Route path=":skpiId" element={<CommonPages.TabelKegiatanPage />} />
              <Route path=":skpiId/:kegiatanId" element={<CommonPages.TabelTingkatPage />} />
              <Route path=":skpiId/:kegiatanId/:tingkatId" element={<CommonPages.TabelPrestasiPage />} />
            </Route>
            <Route path="transkrip-skpi">
              <Route index element={<UserPages.TranskripPage />} />
              <Route path="tambah" element={<UserPages.FormulirSkpiPage />} />
              <Route path="edit" element={<UserPages.EditSkpiPage />} />
            </Route>
            <Route path="pesan" element={<CommonPages.PesanPage />}>
              <Route path=":ruangPesanId" element={<CommonPages.PesanDetailPage />} />
            </Route>
            <Route path="pengaturan" element={<UserPages.PengaturanPage />} />
            <Route path="contoh-transkrip" element={<UserPages.ContohTranskripPage />} />
          </Route>

          <Route path="*" element={<CommonPages.NotFoundPage />} />
        </Routes>
      </React.Suspense>
    </ChakraProvider>
  );
}
