import jwtDecode from "jwt-decode";
import { UserRole } from "../models";
import { appStorage } from "../helpers";

declare type DecodedToken = {
  id: number;
  username: string;
  nama: string;
  role: UserRole;
  iat: number;
  exp: number;
};

export function useCheckToken(): [boolean, DecodedToken | null] {
  const token = appStorage.get("token");
  if (!token) return [false, null];

  const decodedToken: DecodedToken = jwtDecode(token);
  const isValid = decodedToken.exp * 1000 > Date.now();

  return [isValid, decodedToken];
}
