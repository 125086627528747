import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBoolean,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { createUserService, createSkpiService, createTranskripService } from "../../services";
import { useCheckToken, useCreateMessage } from "../../hooks";
import React from "react";
import { ILampiran, ISkpi, ITranskrip, IUser, UserRole } from "../../models";
import { BackButton, ConfirmPrompt, Loading, PdfViewer } from "../../components";
import { FaCheck, FaEdit, FaExclamation, FaExternalLinkAlt, FaFilePdf, FaLink, FaQuestionCircle } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { parsePageName, parseSkpiGrade, toRomanString } from "../../helpers";
import { FiCheckSquare, FiPrinter, FiSend, FiX } from "react-icons/fi";
import { useCetakSKPI } from "../../hooks/cetak";

export default function TranskripDetailPage() {
  const pageName = parsePageName(useLocation().pathname);
  const { userId } = useParams();
  const [, user] = useCheckToken();
  const toast = useToast();
  const navigate = useNavigate();
  const userService = createUserService();
  const transkripService = createTranskripService();
  const skpiService = createSkpiService();
  const [userData, setuserData] = React.useState<IUser | null>(null);
  const [transkripData, settranskripData] = React.useState<ITranskrip[] | null>(null);
  const [skpiData, setskpiData] = React.useState<ISkpi[] | null>(null);
  const [selectedData, setselectedData] = React.useState<ITranskrip | null>(null);
  const [lampiran, setlampiran] = React.useState<ILampiran | null>(null);
  const [alasanPenolakan, setalasanPenolakan] = React.useState<string | null>(null);
  const [rejectModalOpen, rejectModal] = useBoolean();
  const [approveModalOpen, approveModal] = useBoolean();
  const [showPdfModal, pdfModal] = useBoolean();
  const tableSchema = useColorModeValue("blackAlpha", "gray");
  const { createMessage, isChatLoading } = useCreateMessage();
  const { onCetak, isLoading: cetakLoading } = useCetakSKPI();

  async function fetchUser() {
    const result = await userService.get(parseInt(userId!));
    setuserData(result.data);
  }

  async function fetchTranskrip() {
    const result = await transkripService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "no",
      order: "asc",
      filter: { user: { id: parseInt(userId!) } },
    });
    settranskripData(result.data);
  }

  async function fetchSkpi() {
    const result = await skpiService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "no",
      order: "asc",
    });
    setskpiData(result.data);
  }

  function onSetujuiKeseluruhan() {
    const rekapStatus = transkripData?.every((x) => x.disetujui === true);
    if (!rekapStatus) toast({
      title: "Peringatan",
      description: "Pastikan semua item telah diperiksa dan disetujui sebelum melakukan aksi ini",
      status: "warning",
      duration: 3000,
    });
    else approveModal.on();
  }

  async function onSubmit() {
    const result = await userService.put({
      id: userData?.id,
      disetujui: true,
    });
    if (!result.error) {
      fetchUser();
      approveModal.off();
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onSetujui(id: number) {
    const result = await transkripService.postPersetujuan(id);
    if (!result.error) fetchTranskrip();
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onTolak(id: number) {
    const result = await transkripService.postPersetujuan(id, alasanPenolakan || "");
    if (!result.error) {
      fetchTranskrip();
      setalasanPenolakan(null);
      setselectedData(null);
      rejectModal.off();
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  React.useEffect(() => {
    fetchUser();
    fetchSkpi();
    fetchTranskrip();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (userData?.role === UserRole.ADMIN) navigate(
      `/admin/${pageName}`,
      { replace: true }
    );
    // eslint-disable-next-line
  }, [userData]);

  if (!userData || !skpiData || !transkripData) return <Loading />;

  return (
    <Box>
      <Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
        <Flex alignItems={"center"} gap={2}>
          <BackButton />
          <Heading size={{ base: "sm", md: "md" }}>
            Rincian Transkrip SKPI Mahasiswa
          </Heading>
        </Flex>
        <Flex hidden={!userData.disetujui || pageName === "draft"}>
          <Button
            onClick={() => onCetak(userData, skpiData)}
            isLoading={cetakLoading}
            colorScheme="green">
            <FiPrinter />
            <Text ml={2}>Cetak</Text>
          </Button>
        </Flex>
        <Flex hidden={userData.disetujui || pageName === "draft"}>
          <Button
            onClick={onSetujuiKeseluruhan}
            size={{ base: "xs", md: "sm" }}
            colorScheme="green">
            <FiCheckSquare />
            <Text ml={2}>Setujui keseluruhan</Text>
          </Button>
        </Flex>
      </Flex>
      <Flex flexDir={"column"} alignItems={"start"} gap={1} p={4} border={"1px"} borderColor={"gray.600"} borderRadius={"lg"} fontSize={"sm"}>
        <Text>Nama: {userData.nama}</Text>
        <Text>NIM: {userData.username}</Text>
        <Text>No. HP: {userData.hp}</Text>
        <Text>Email: {userData.email || "-"}</Text>
        <Flex alignItems={"center"} gap={2}>
          <Text>Predikat SKPI:</Text>
          <Badge colorScheme="cyan" variant={"outline"}>
            {parseSkpiGrade(transkripData.reduce((a, b) => a + b.prestasi.poin, 0) || 0)}
          </Badge>
        </Flex>
        <Button
          onClick={() => createMessage(userData.id)}
          size={{ base: "xs", md: "sm" }}
          mt={4}
          isLoading={isChatLoading}
          colorScheme="linkedin">
          <FiSend />
          <Text ml={2}>Kirim pesan</Text>
        </Button>
      </Flex>
      <TableContainer fontSize={{ base: "sm", md: "md" }}>
        <Table colorScheme={tableSchema}>
          <Thead>
            <Tr>
              <Th w={1}>#</Th>
              <Th w={1}>No.</Th>
              <Th>Nama Kegiatan</Th>
              <Th>Tingkat / Tempat</Th>
              <Th>Semester - Tahun</Th>
              <Th>Nilai SKP</Th>
              <Th>Bukti Fisik</Th>
              <Th hidden={userData.disetujui || pageName === "draft"}>Status Terkini</Th>
              <Th hidden={userData.disetujui || pageName === "draft"}>Aksi</Th>
            </Tr>
          </Thead>
          {skpiData.map((skpi) => (
            <React.Fragment key={skpi.id}>
              <Thead>
                <Tr bgColor={"whiteAlpha.50"}>
                  <Th colSpan={2}>{skpi.no}</Th>
                  <Th colSpan={7}>{skpi.jenis} - {skpi.nama}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {transkripData.filter((x) => x.skpi.no === skpi.no).map((transkrip, index) => {
                  const startNum = transkripData.filter((t) => t.skpi.no < transkrip.skpi.no).length + index + 1;

                  return (
                    <Tr key={transkrip.id}>
                      <Td></Td>
                      <Td>{index + 1}</Td>
                      <Td>{transkrip.nama}</Td>
                      <Td>{transkrip.tingkat.nama}</Td>
                      <Td>{toRomanString(transkrip.semester)} - {transkrip.tahun}</Td>
                      <Td>{transkrip.prestasi.poin}</Td>
                      <Td>
                        <Flex gap={2}>
                          <Button
                            colorScheme="teal"
                            size={{ base: "xs", md: "sm" }}
                            onClick={() => {
                              navigate(
                                `${user?.role === UserRole.ADMIN ? "/admin" : "/app"
                                }/tabel-skpi/${transkrip.skpi.id
                                }/${transkrip.kegiatan.id
                                }/${transkrip.tingkat.id}`
                              );
                            }}>
                            <FaExternalLinkAlt />
                            <Text ml={2}>
                              {startNum} ({transkrip.skpi.no}-{transkrip.kegiatan.no})
                            </Text>
                          </Button>
                          {transkrip.lampiran ? (
                            <IconButton
                              colorScheme="green"
                              icon={transkrip.lampiran.path ? <FaFilePdf /> : <FaLink />}
                              size={{ base: "xs", md: "sm" }}
                              aria-label="lihat lampiran"
                              onClick={() => {
                                if (transkrip.lampiran.path) {
                                  setlampiran(transkrip.lampiran);
                                  pdfModal.on();
                                }
                                else window.open(transkrip.lampiran.url, "_blank");
                              }} />
                          ) : <FaExclamation color="red" />}
                        </Flex>
                      </Td>
                      <Td hidden={userData.disetujui || pageName === "draft"}>
                        {!userData.diajukan ? (
                          <Badge colorScheme="orange">Belum diajukan</Badge>
                        ) : (
                          <Flex gap={2} alignItems={"center"}>
                            <Badge colorScheme={
                              transkrip.disetujui
                                ? "green"
                                : transkrip.alasanPenolakan
                                  ? "red"
                                  : "yellow"}>
                              {transkrip.disetujui
                                ? "Disetujui"
                                : transkrip.alasanPenolakan
                                  ? "Ditolak"
                                  : "Belum ditanggapi"}
                            </Badge>
                            <Tooltip hasArrow label={transkrip.alasanPenolakan}>
                              <Box hidden={!transkrip.alasanPenolakan}>
                                <FaQuestionCircle />
                              </Box>
                            </Tooltip>
                          </Flex>
                        )}
                      </Td>
                      <Td hidden={userData.disetujui || pageName === "draft"}>
                        <Flex gap={2}>
                          <IconButton
                            colorScheme="orange"
                            icon={<FaEdit />}
                            size={{ base: "xs", md: "sm" }}
                            aria-label="Edit SKPI"
                            onClick={() => {
                              navigate("edit", { state: { oldTranskrip: { ...transkrip, user: userData } } });
                            }} />
                          <IconButton
                            colorScheme="green"
                            icon={<FaCheck />}
                            size={{ base: "xs", md: "sm" }}
                            aria-label="setujui"
                            onClick={() => {
                              if (!transkrip.disetujui) onSetujui(transkrip.id);
                            }} />
                          <IconButton
                            colorScheme="red"
                            icon={<FiX />}
                            fontWeight={"bold"}
                            size={{ base: "xs", md: "sm" }}
                            aria-label="tolak"
                            onClick={() => {
                              setselectedData(transkrip);
                              rejectModal.on();
                            }} />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td textAlign={"center"} colSpan={3} fontWeight={"semibold"}>Jumlah</Td>
                  <Td colSpan={4} fontWeight={"semibold"}>
                    {transkripData.filter((x) => x.skpi.no === skpi.no).reduce((a, b) => a + b.prestasi.poin, 0) || 0}
                  </Td>
                </Tr>
              </Tbody>
            </React.Fragment>
          ))}
          <Tfoot>
            <Tr>
              <Td></Td>
              <Td></Td>
              <Td textAlign={"center"} colSpan={3} fontWeight={"bold"}>Jumlah Total</Td>
              <Td colSpan={4} fontWeight={"bold"}>
                {transkripData.reduce((a, b) => a + b.prestasi.poin, 0) || 0}
              </Td>
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <ConfirmPrompt
        title="Yakin menolak SKP ini?"
        description={`Silahkan isi alasan penolakan / catatan revisi untuk kegiatan "${selectedData?.nama}" di bawah ini.`}
        isOpen={rejectModalOpen}
        onChange={(e) => setalasanPenolakan(e.target.value)}
        onApprove={() => onTolak(selectedData?.id!)}
        onReject={() => {
          rejectModal.off();
          setselectedData(null);
        }} />
      <ConfirmPrompt
        title="Yakin menyetujui keseluruhan SKPI ini?"
        description={`Setelah mengonfirmasi ini, maka Transkrip SKPI mahasiswa ${userData.nama} (${userData.username} - ${userData.prodi}) akan ditandai sebagai data yang valid dan tidak dapat diedit kembali oleh pihak operator maupun mahasiswa.`}
        isOpen={approveModalOpen}
        onApprove={onSubmit}
        onReject={approveModal.off} />
      <PdfViewer
        lampiran={lampiran}
        isOpen={showPdfModal}
        onClose={pdfModal.off}
      />
    </Box>
  );
}
