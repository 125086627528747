import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
  Button,
  Heading,
  IconButton,
  useToast,
  useBoolean,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  BackButton,
  ConfirmPrompt,
  Loading,
  ModalForm,
  OptionItem,
  PaginationControl,
  Search,
  SearchDataType,
} from "../../../components";
import { createKegiatanService } from "../../../services";
import React from "react";
import { ApiResponse, IKegiatan, Pagination, UserRole } from "../../../models";
import { useNavigate, useParams } from "react-router-dom";
import { FaEdit, FaExternalLinkAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useCheckToken } from "../../../hooks";

const searchOptions: OptionItem[] = [
  { label: "No", value: "no" },
  { label: "Nama", value: "nama" },
];

export default function TabelKegiatanPage() {
  const { skpiId } = useParams();
  const [, user] = useCheckToken();
  const kegiatanService = createKegiatanService();
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = React.useState<ApiResponse<IKegiatan[]> | null>(null);
  const [selectedData, setselectedData] = React.useState<IKegiatan | null>(null);
  const [pagination, setPagination] = React.useState<Pagination>({
    current: 1,
    count: 0,
    last: 0,
  });
  const [searchData, setsearchData] = React.useState<SearchDataType>({
    searchBy: "no",
    search: "",
  });
  const [addModalOpen, addModal] = useBoolean();
  const [editModalOpen, editModal] = useBoolean();
  const [deleteModalOpen, deleteModal] = useBoolean();
  const tableSchema = useColorModeValue("blackAlpha", "gray");

  async function fetchData() {
    const result = await kegiatanService.getAll({
      page: pagination.current,
      limit: 10,
      orderBy: "no",
      order: "asc",
      searchBy: searchData.searchBy,
      search: searchData.search,
      filter: { skpi: { id: Number(skpiId) } },
    });
    setData(result);
    setPagination(result.pagination!);
  }

  async function onAdd(values: IKegiatan) {
    const result = await kegiatanService.post({
      ...values,
      skpi: { id: Number(skpiId) },
    });
    if (!result.error) {
      fetchData();
      addModal.off();
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onEdit(values: Partial<IKegiatan>) {
    const result = await kegiatanService.put(values);
    if (!result.error) {
      fetchData();
      editModal.off();
      setselectedData(null);
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onDelete(id: number) {
    const result = await kegiatanService.delete(id);
    if (!result.error) {
      fetchData();
      editModal.off();
      setselectedData(null);
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pagination.current, searchData.search]);

  if (!data) return <Loading />;

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={3} mb={3}>
          <BackButton />
          <Heading size={{ base: "sm", md: "md" }} isTruncated>Daftar kegiatan - Tabel {skpiId}</Heading>
        </Flex>
        <IconButton
          hidden={user?.role !== UserRole.ADMIN}
          colorScheme="teal"
          icon={<FaPlus />}
          size={{ base: "xs", md: "sm" }}
          aria-label="Tambah kegiatan"
          onClick={addModal.on} />
      </Flex>
      <Search onSearch={(value) => setsearchData(value)} options={searchOptions} />
      <TableContainer fontSize={{ base: "sm", md: "md" }}>
        <Table colorScheme={tableSchema} variant="striped">
          <Thead>
            <Tr>
              <Th w={1}>No.</Th>
              <Th>Nama kegiatan</Th>
              <Th>Tingkat</Th>
              <Th hidden={user?.role !== UserRole.ADMIN}>Aksi</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.data?.map((kegiatan) => (
              <Tr key={kegiatan.id}>
                <Td>{kegiatan.no}</Td>
                <Td maxW={{ base: 300, md: 400, lg: "100%" }} isTruncated>{kegiatan.nama}</Td>
                <Td>
                  <Flex gap={3}>
                    <Button
                      colorScheme="teal"
                      size={{ base: "xs", md: "sm" }}
                      onClick={() => navigate(`${kegiatan.id}`)}>
                      <FaExternalLinkAlt />
                      <Text ml={2}>Lihat Tingkat</Text>
                    </Button>
                  </Flex>
                </Td>
                <Td hidden={user?.role !== UserRole.ADMIN}>
                  <Flex gap={2}>
                    <IconButton
                      colorScheme="orange"
                      icon={<FaEdit />}
                      size={{ base: "xs", md: "sm" }}
                      aria-label="Edit kegiatan"
                      onClick={() => {
                        setselectedData(kegiatan);
                        editModal.on();
                      }} />
                    <IconButton
                      colorScheme="red"
                      icon={<FaTrash />}
                      size={{ base: "xs", md: "sm" }}
                      aria-label="Hapus kegiatan"
                      onClick={() => {
                        setselectedData(kegiatan);
                        deleteModal.on();
                      }} />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationControl
        pagination={pagination}
        onPrev={() =>
          setPagination((prev) => ({
            ...prev, current: prev.current > 1
              ? prev.current - 1
              : prev.current
          }))
        }
        onNext={() =>
          setPagination((prev) => ({
            ...prev, current: prev.last !== 0 && prev.current !== prev.last
              ? prev.current + 1
              : prev.current
          }))
        } />
      <AddForm
        isOpen={addModalOpen}
        onClose={addModal.off}
        onSubmit={onAdd} />
      {selectedData ? (
        <>
          <EditForm
            isOpen={editModalOpen}
            onClose={() => {
              editModal.off();
              setselectedData(null);
            }}
            onSubmit={onEdit}
            currentData={selectedData} />
          <ConfirmPrompt
            title="Yakin hapus kegiatan ini?"
            description="Setelah kegiatan ini dihapus, seluruh data yang terhubung seperti tingkat, dll. akan ikut terhapus secara permanen."
            isOpen={deleteModalOpen}
            onApprove={() => onDelete(selectedData.id)}
            onReject={() => {
              deleteModal.off();
              setselectedData(null);
            }} />
        </>
      ) : null}
    </Box>
  );
}

declare type AddFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IKegiatan) => void;
};

function AddForm({
  isOpen,
  onClose,
  onSubmit,
}: AddFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IKegiatan>();

  return (
    <ModalForm
      title="Tambah kegiatan"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}>
      <FormControl mt={3} isInvalid={!(!errors.no)}>
        <FormLabel>No.</FormLabel>
        <Input
          type="number"
          {...register("no", {
            required: "No. kegiatan tidak boleh kosong",
          })} />
        <FormErrorMessage>
          {errors.no?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3} isInvalid={!(!errors.nama)}>
        <FormLabel>Nama kegiatan</FormLabel>
        <Input
          {...register("nama", {
            required: "Nama kegiatan tidak boleh kosong",
          })} />
        <FormErrorMessage>
          {errors.nama?.message}
        </FormErrorMessage>
      </FormControl>
    </ModalForm>
  );
}

declare type EditFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IKegiatan) => void;
  currentData: IKegiatan;
};

function EditForm({
  isOpen,
  onClose,
  onSubmit,
  currentData,
}: EditFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IKegiatan>();

  if (!currentData) return null;

  return (
    <ModalForm
      title="Edit kegiatan"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}>
      <Input {...register("id", { value: currentData.id })} display={"none"} />
      <FormControl mt={3} isInvalid={!(!errors.no)}>
        <FormLabel>No.</FormLabel>
        <Input
          type="number"
          {...register("no", {
            value: currentData.no,
          })} />
        <FormErrorMessage>
          {errors.no?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3} isInvalid={!(!errors.nama)}>
        <FormLabel>Nama kegiatan</FormLabel>
        <Input
          {...register("nama", {
            required: "Nama kegiatan tidak boleh kosong",
            value: currentData.nama,
          })} />
        <FormErrorMessage>
          {errors.nama?.message}
        </FormErrorMessage>
      </FormControl>
    </ModalForm>
  );
}
