import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  MenuDivider,
} from '@chakra-ui/react';
import {
  FiMenu,
  FiChevronDown,
  FiSettings,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { Link, useNavigate } from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { useCheckToken } from "../hooks";
import { appStorage } from "../helpers";
import { ReactElement } from "react";
import { UserRole } from "../models";
import { FaDoorOpen, FaExclamationCircle } from "react-icons/fa";

interface NavItemProps extends FlexProps {
  icon: IconType;
  path: string;
  children: React.ReactNode;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

export interface LinkItemProps {
  name: string;
  path: string;
  icon: IconType;
}

interface SidebarContentProps extends BoxProps {
  linkItems: LinkItemProps[];
  onClose: () => void;
}

interface SidebarProps {
  linkItems: LinkItemProps[];
  children: ReactElement;
}

function SidebarContent({ linkItems, onClose, ...rest }: SidebarContentProps) {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image
          src={require("../assets/images/logo.png")}
          alt="logo"
          maxH="100%"
          py={4} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {linkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} path={link.path} onClick={onClose}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
}

function NavItem({ icon, path, children, ...rest }: NavItemProps) {
  return (
    <Link to={path}>
      <Box
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'cyan.400',
            color: 'white',
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: 'white',
              }}
              as={icon} />
          )}
          {children}
        </Flex>
      </Box>
    </Link>
  );
}

function MobileNav({ onOpen, ...rest }: MobileProps) {
  const [, user] = useCheckToken();
  const navigate = useNavigate();

  function onSetting() {
    navigate(
      user?.role === UserRole.ADMIN
        ? "/admin/pengaturan"
        : "/app/pengaturan"
    );
  }

  function onHimbauan() {
    navigate("/admin/himbauan");
  }

  function signOut() {
    appStorage.remove("token");
    navigate("/login");
  }

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />} />

      <Image
        src={require("../assets/images/logo.png")}
        alt="logo"
        display={{ base: "flex", md: "none" }}
        maxH="100%"
        py={4} />

      <HStack spacing={{ base: '0', md: '6' }}>
        <ColorModeSwitcher />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  src={require(
                    user?.role === "ADMIN"
                      ? "../assets/images/admin.png"
                      : "../assets/images/mahasiswa.png"
                  )} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">{user?.nama}</Text>
                  <Text fontSize="xs" color="gray.600">{user?.role}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem onClick={onSetting}>
                <FiSettings />
                <Text ml={2}>Pengaturan</Text>
              </MenuItem>
              <MenuItem onClick={onHimbauan}>
                <FaExclamationCircle />
                <Text ml={2}>Himbauan</Text>
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={signOut}>
                <FaDoorOpen />
                <Text ml={2}>Keluar</Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
}

export function Sidebar({ linkItems, children }: SidebarProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent linkItems={linkItems} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent linkItems={linkItems} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}
