import { Badge, Box, Button, Flex, IconButton, Text, Wrap, WrapItem, useColorModeValue } from "@chakra-ui/react";
import { FiCheckCircle, FiClock, FiEdit, FiEdit3, FiInbox, FiMessageSquare, FiPhone } from "react-icons/fi";
import { DashboardCard, Loading, ProfileChecker } from "../../components";
import React from "react";
import { IHimbauan, IUser } from "../../models";
import { createHimbauanService, createUserService } from "../../services";
import { useCheckToken } from "../../hooks";
import { useNavigate } from "react-router-dom";

export default function DashboardPage() {
  const [, user] = useCheckToken();
  const navigate = useNavigate();
  const userService = createUserService();
  const himbauanService = createHimbauanService();
  const [userData, setuserData] = React.useState<IUser | null>(null);
  const [himbauanData, sethimbauanData] = React.useState<IHimbauan | null>(null);
  const [jumlahRevisi, setjumlahRevisi] = React.useState<number>(0);
  const warningBg = useColorModeValue("red.200", "red.800");

  async function fetchuser() {
    const result = await userService.get(user?.id!);
    const himbauanResult = await himbauanService.get();
    setuserData(result.data);
    sethimbauanData(himbauanResult.data);
    setjumlahRevisi(result.data.transkrip.filter((x) => !!x.alasanPenolakan).length);
  }

  React.useEffect(() => {
    fetchuser();
    // eslint-disable-next-line
  }, []);

  if (!userData || !himbauanData) return <Loading />;

  return (
    <ProfileChecker userData={userData}>
      <Flex justifyContent={"space-between"} flexDir={"column"} gap={3} minH={"83vh"}>
        <Box>
          <Text
            textAlign={{ base: "center", md: "left" }}
            mb={4}>
            Selamat datang, {user?.nama}.
          </Text>
          <Flex
            hidden={userData.transkrip.length > 0}
            alignItems={"center"}
            bg={warningBg}
            borderRadius={"lg"}
            flexDir={{ base: "column", md: "row" }}
            my={6}
            p={4}
            gap={2}>
            <Text>Anda belum mengisi data SKPI apa pun.</Text>
            <Button
              onClick={() => navigate("transkrip-skpi/tambah")}
              size={"sm"}
              colorScheme="teal"
              leftIcon={<FiEdit />}>
              Mulai isi sekarang
            </Button>
          </Flex>
          <Wrap spacing={6} justify={{ base: "center", md: "start" }}>
            <WrapItem>
              <DashboardCard
                label={
                  userData.disetujui && userData.diajukan
                    ? "Transkrip SKPI Anda telah disetujui"
                    : !userData.disetujui && userData.diajukan
                      ? "Transkrip SKPI Anda sedang diperiksa"
                      : "Anda belum mengajukan Transkrip SKPI"
                }
                onClick={() => navigate("transkrip-skpi")}
                iconSize={36}
                Icon={
                  userData.disetujui && userData.diajukan
                    ? FiCheckCircle
                    : !userData.disetujui && userData.diajukan
                      ? FiClock
                      : FiEdit
                } />
            </WrapItem>
            <WrapItem hidden={jumlahRevisi === 0}>
              <DashboardCard
                label="Jumlah revisi saat ini"
                value={jumlahRevisi.toString()}
                onClick={() => navigate("transkrip-skpi")}
                Icon={FiEdit3} />
            </WrapItem>
          </Wrap>
        </Box>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={3}
          p={3}
          border={"1px"}
          borderRadius={"md"}
          borderColor={"teal.700"}
          h={"fit-content"}
          fontSize={"sm"}>
          <Box w={"full"}>
            <Text fontWeight={"bold"} mb={1}>
              Jika ada kendala, hubungi operator:
            </Text>
            <Flex
              alignItems={"center"}
              gap={12}
              justifyContent={{ base: "space-between", sm: "start" }}>
              <Box>
                <Text display={"flex"} alignItems={"center"} gap={2}>
                  <FiPhone /> {himbauanData.hp}
                </Text>
                <Text display={"flex"} alignItems={"center"} gap={2}>
                  <FiInbox /> {himbauanData.email}
                </Text>
              </Box>
              <IconButton
                icon={<FiMessageSquare />}
                rounded={"full"}
                size={"sm"}
                colorScheme="teal"
                onClick={() => navigate("/app/pesan")}
                aria-label="kirim pesan" />
            </Flex>
          </Box>
          {(himbauanData.judul || himbauanData.deskripsi) && (
            <Box>
              <Badge colorScheme="orange" mb={1}>
                Himbauan
              </Badge>
              <Text display={"flex"} fontWeight={"semibold"} alignItems={"center"} gap={2}>
                {himbauanData.judul}
              </Text>
              <Text display={"flex"} alignItems={"center"} gap={2}>
                {himbauanData.deskripsi}
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
    </ProfileChecker>
  );
}
