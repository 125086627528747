import LoginPage from "./login.page";
import ForbiddenPage from "./forbidden.page";
import NotFoundPage from "./not-found.page";
import TabelSkpiPage from "./tabel-skpi/skpi.page";
import TabelKegiatanPage from "./tabel-skpi/kegiatan.page";
import TabelTingkatPage from "./tabel-skpi/tingkat.page";
import TabelPrestasiPage from "./tabel-skpi/prestasi.page";
import PesanPage from "./pesan/pesan.page";
import PesanDetailPage from "./pesan/pesan-detail.page";

export const CommonPages = {
  LoginPage,
  TabelSkpiPage,
  TabelKegiatanPage,
  TabelTingkatPage,
  TabelPrestasiPage,
  ForbiddenPage,
  NotFoundPage,
  PesanPage,
  PesanDetailPage,
};
