import { ITranskrip } from "./transkrip";

export enum UserRole {
  ADMIN = "ADMIN",
  MAHASISWA = "MAHASISWA",
}

export enum Jenjang {
  S1 = "S1",
  D3 = "D3",
}

export interface IUser {
  id: number;
  nama: string;
  username: string;
  jenjang: Jenjang;
  prodi: string | null;
  hp: string | null;
  email: string | null;
  role: UserRole;
  diajukan: boolean;
  diajukanPada: Date | null;
  disetujui: boolean;
  disetujuiPada: Date | null;
  transkrip: ITranskrip[];
}
