import { ITranskrip, JenisSkpi, SkpiGrade } from "../models";
import config from "./config";

export function debounce<T extends Function>(cb: T, wait = 20) {
  let h: any = 0;
  let callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  return (callable as any);
}

export function toRomanString(num: number): string {
  if (num < 1) return `${num}`;

  type RomanLookup = Array<[string, number]>;

  const lookup: RomanLookup = [
    ['M', 1000],
    ['CM', 900],
    ['D', 500],
    ['CD', 400],
    ['C', 100],
    ['XC', 90],
    ['L', 50],
    ['XL', 40],
    ['X', 10],
    ['IX', 9],
    ['V', 5],
    ['IV', 4],
    ['I', 1],
  ];
  return lookup.reduce((acc, [k, v]) => {
    acc += k.repeat(Math.floor(num / v));
    num = num % v;
    return acc;
  }, '');
};

export function parseFilepath(path: string): string {
  return `${config.get("BASE_URL")}/storage${path.split("storage").pop()}`;
}

export function parseSkpiGrade(totalSkp: number): SkpiGrade {
  if (totalSkp > 251) return SkpiGrade.A;
  else if (totalSkp > 150 && totalSkp < 251) return SkpiGrade.B;
  else if (totalSkp > 44 && totalSkp < 151) return SkpiGrade.C;
  else return SkpiGrade.D;
}

export function toFormattedDate(date: Date, hideTime?: boolean) {
  const dateObj = new Date(date);
  const months: string[] = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  if (hideTime) return `${dateObj.getDate()} ${months[dateObj.getMonth()]} ${dateObj.getFullYear()}`;
  return `${dateObj.getDate()}-${dateObj.getMonth() + 1}-${dateObj.getFullYear()} ${dateObj.toLocaleTimeString()}`;
}

export function parsePageName(
  pathname: string
): "pengajuan-skpi" | "riwayat" | "draft" | "/" {
  return pathname.split("admin/").pop()?.split("/")[0] || "/" as any;
}

export function downloadFrom(url: string, filename: string) {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.style.display = "none";
  a.click();
  a.remove();
}

export function isWajibFilled(transkrip: ITranskrip[] | null) {
  if (!transkrip) return false;

  const skpiWajib = transkrip.filter((x) => x.skpi.jenis === JenisSkpi.WAJIB);
  const kegiatanNo = skpiWajib.map((x) => x.kegiatan.no);

  if (kegiatanNo.includes(1) && kegiatanNo.includes(2)) return true;

  return false;
}
