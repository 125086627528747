import {
  Badge,
  Box,
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBoolean,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { IUser, Pagination, SkpiGrade } from "../../models";
import { createUserService } from "../../services";
import { Loading, OptionItem, PaginationControl, Search, SearchDataType } from "../../components";
import { parseSkpiGrade, toFormattedDate } from "../../helpers";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";

const searchOptions: OptionItem[] = [
  { label: "Nama", value: "nama" },
  { label: "NIM", value: "username" },
  { label: "Jenjang", value: "jenjang" },
  { label: "Prodi", value: "prodi" },
  { label: "No. HP", value: "hp" },
  { label: "Email", value: "email" },
];

export default function PengajuanSkpiPage() {
  const navigate = useNavigate();
  const userService = createUserService();
  const [isLoading, loading] = useBoolean();
  const [userData, setuserData] = React.useState<IUser[] | null>(null);
  const [pageParams, setPageParams] = useSearchParams();
  const [pagination, setPagination] = React.useState<Pagination>({
    current: Number(pageParams.get("page") || "1"),
    count: 0,
    last: 0,
  });
  const [searchData, setsearchData] = React.useState<SearchDataType>({
    searchBy: "nama",
    search: "",
  });
  const tableSchema = useColorModeValue("blackAlpha", "gray");

  async function fetchUser() {
    loading.on();
    const result = await userService.getAll({
      page: pagination.current,
      limit: 10,
      orderBy: "diajukanPada",
      order: "asc",
      searchBy: searchData.searchBy,
      search: searchData.search,
      filter: { diajukan: true, disetujui: false },
    });
    loading.off();
    setuserData(result.data);
    setPagination(result.pagination!);
  }

  React.useEffect(() => {
    setPageParams((prev) => {
      prev.set("page", `${pagination.current}`);
      return prev;
    });
    fetchUser();
    // eslint-disable-next-line
  }, [pagination.current, searchData.search]);

  if (!userData) return <Loading />;

  return (
    <Box>
      <Heading size={{ base: "sm", md: "md" }} mb={3}>
        Daftar Pengajuan Transkrip SKPI Mahasiswa
      </Heading>
      <Search onSearch={(value) => setsearchData(value)} options={searchOptions} />
      {isLoading ? <Loading position="relative" marginY={20} /> : (
        <TableContainer fontSize={{ base: "sm", md: "md" }}>
          <Table colorScheme={tableSchema} variant={"striped"}>
            <Thead>
              <Tr>
                <Th w={1}>No.</Th>
                <Th>Nama</Th>
                <Th>NIM</Th>
                <Th>Jenjang</Th>
                <Th>Prodi</Th>
                <Th>No. HP</Th>
                <Th>Email</Th>
                <Th>Predikat</Th>
                <Th>Diajukan pada</Th>
                <Th>Aksi</Th>
              </Tr>
            </Thead>
            <Tbody>
              {userData.map((x, i) => {
                const grade = parseSkpiGrade(x.transkrip.reduce(
                  (a, b) => a + b.prestasi.poin, 0) || 0
                );

                return (
                  <Tr key={x.id}>
                    <Td>{i + 1 + (10 * pagination.current - 10)}</Td>
                    <Td>{x.nama}</Td>
                    <Td>{x.username}</Td>
                    <Td>{x.jenjang}</Td>
                    <Td>{x.prodi}</Td>
                    <Td>{x.hp}</Td>
                    <Td>{x.email || "-"}</Td>
                    <Td>
                      <Badge colorScheme={
                        grade === SkpiGrade.A
                          ? "green"
                          : grade === SkpiGrade.B
                            ? "orange"
                            : "yellow"
                      }>
                        {grade}
                      </Badge>
                    </Td>
                    <Td>{toFormattedDate(x.diajukanPada!)}</Td>
                    <Td>
                      <Button
                        colorScheme="teal"
                        size={{ base: "xs", md: "sm" }}
                        onClick={() => navigate(`${x.id}`)}>
                        <FaExternalLinkAlt />
                        <Text ml={2}>Rincian</Text>
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <PaginationControl
        pagination={pagination}
        onPrev={() =>
          setPagination((prev) => ({
            ...prev, current: prev.current > 1
              ? prev.current - 1
              : prev.current
          }))
        }
        onNext={() =>
          setPagination((prev) => ({
            ...prev, current: prev.last !== 0 && prev.current !== prev.last
              ? prev.current + 1
              : prev.current
          }))
        } />
    </Box>
  );
}
