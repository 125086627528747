declare type AppStorage = {
  token: string;
};

declare type AppStorageKeys = keyof AppStorage;

// eslint-disable-next-line
export default {
  get(key: AppStorageKeys): string | null {
    return localStorage.getItem(key);
  },
  set(key: AppStorageKeys, value: string) {
    localStorage.setItem(key, value);
  },
  remove(key: AppStorageKeys) {
    localStorage.removeItem(key);
  }
};
