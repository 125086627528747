/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Badge, Box, Flex, Input, Text, useColorModeValue } from "@chakra-ui/react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { createPesanService } from "../../../services";
import { Loading } from "../../../components";
import React from "react";
import { useCheckToken } from "../../../hooks";
import { UserRole, IRuangPesan } from "../../../models";

export default function PesanPage() {
  const [, user] = useCheckToken();
  const [ruangPesan, setruangPesan] = React.useState<IRuangPesan[] | null>(null);
  const [ruang, setruang] = React.useState<IRuangPesan | null>(null);
  const [searchText, setsearchText] = React.useState<string>("");
  const { ruangPesanId } = useParams();
  const pesanServive = createPesanService();
  const filteredRuang = React.useMemo(
    () => ruangPesan?.filter(
      (item) => item.user.nama?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.user.username?.toLowerCase().includes(searchText.toLowerCase())
    ),
    [ruangPesan, searchText]
  );

  async function fetchRuangPesan() {
    let result = await pesanServive.getRuangPesan();

    if (!result.data.length && user?.role === UserRole.MAHASISWA) {
      const temp = await pesanServive.create(user.id);
      if (temp.error) return;

      result = { ...temp, data: [temp.data] };
    }

    setruangPesan(result.data);
  }

  React.useEffect(() => {
    fetchRuangPesan();
  }, [ruangPesanId]);

  if (!ruangPesan || !filteredRuang) return <Loading />;

  return (
    <Flex w={"full"}>
      <Flex
        display={{ base: !ruangPesanId ? "flex" : "none", md: "flex" }}
        flexDir={"column"}
        w={{ base: "full", md: "md" }}
        h={"full"}>
        <Input
          mb={3}
          size={"sm"}
          rounded={"md"}
          placeholder="Cari Nama/NIM"
          onChange={(e) => setsearchText(e.target.value)} />
        <Box overflowY={"auto"} h={"80vh"} pr={4}>
          {filteredRuang.map((item) => (
            <ChatRoomListItem
              key={item.id}
              ruangPesan={item}
              onSelect={setruang}
              selected={ruangPesanId === item.id}
            />
          ))}
        </Box>
      </Flex>
      <Box
        display={{ base: !!ruangPesanId ? "flex" : "none", md: "flex" }}
        w={"full"}>
        {!ruangPesanId ? (
          <Flex w={"full"} alignItems={"center"} justifyContent={"center"}>
            <Text fontStyle={"italic"}>Silahkan pilih pesan</Text>
          </Flex>
        ) : <Outlet context={ruang} />}
      </Box>
    </Flex>
  );
}

function ChatRoomListItem({
  ruangPesan,
  onSelect,
  selected,
}: {
  ruangPesan: IRuangPesan;
  onSelect: (ruang: IRuangPesan) => void;
  selected: boolean;
}) {
  const [, user] = useCheckToken();
  const navigate = useNavigate();

  return (
    <Flex
      onClick={() => {
        onSelect(ruangPesan);
        navigate(`/${user?.role === UserRole.ADMIN ? "admin" : "app"}/pesan/${ruangPesan.id}`);
      }}
      cursor={"pointer"}
      bg={selected ? "cyan.500" : ""}
      _hover={{ background: "cyan.500" }}
      rounded={"md"}
      p={2}
      pr={4}
      my={0.5}
      alignItems={"center"}
      gap={2}
      border={"1px"}
      borderColor={useColorModeValue('gray.400', 'gray.700')}>
      <Avatar
        size={"xs"}
        name={
          user?.role === UserRole.ADMIN
            ? ruangPesan.user.nama
            : ruangPesan.admin.nama
        } />
      <Box>
        <Text fontSize={"sm"} noOfLines={1}>
          {user?.role !== UserRole.ADMIN ? ruangPesan.admin.nama : ruangPesan.user.nama}
        </Text>
        <Flex fontSize={"x-small"}>
          {user?.role === UserRole.ADMIN && (
            <Text mr={2}>{ruangPesan.user.username}</Text>
          )}
          {user?.role === UserRole.ADMIN && (ruangPesan.adminBaca === false) && (
            <span>
              <Badge fontSize={"x-small"} colorScheme="red">
                belum dibaca
              </Badge>
            </span>
          )}
          {user?.role !== UserRole.ADMIN && (ruangPesan.userBaca === false) && (
            <span>
              <Badge fontSize={"x-small"} colorScheme="red">
                belum dibaca
              </Badge>
            </span>
          )}
        </Flex>
      </Box>
    </Flex>
  );
}
