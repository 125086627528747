import React from "react";
import { downloadFrom } from "../../helpers";
import { useNavigate } from "react-router-dom";

export default function ContohTranskripPage() {
  const navigate = useNavigate();

  React.useEffect(() => {
    downloadFrom(
      "/docs/contoh-transkrip-skpi.xlsx",
      "contoh-transkrip-skpi.xlsx"
    );
    navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
