import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Heading,
  IconButton,
  Badge,
  useBoolean,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Text,
  ListItem,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  BackButton,
  ConfirmPrompt,
  Loading,
  ModalForm,
  OptionItem,
  PaginationControl,
  Search,
  SearchDataType,
} from "../../../components";
import { createBuktiService, createPrestasiService } from "../../../services";
import React from "react";
import { ApiResponse, IPrestasi, Pagination, UserRole } from "../../../models";
import { useParams } from "react-router-dom";
import { FaEdit, FaInfoCircle, FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { useFieldArray, useForm } from "react-hook-form";
import { useCheckToken } from "../../../hooks";

const searchOptions: OptionItem[] = [
  { label: "Nama", value: "nama" },
  { label: "Bobot SKP", value: "poin" },
];

export default function TabelPrestasiPage() {
  const { tingkatId } = useParams();
  const [, user] = useCheckToken();
  const prestasiService = createPrestasiService();
  const buktiService = createBuktiService();
  const toast = useToast();
  const [data, setData] = React.useState<ApiResponse<IPrestasi[]> | null>(null);
  const [selectedData, setselectedData] = React.useState<IPrestasi | null>(null);
  const [pagination, setPagination] = React.useState<Pagination>({
    current: 1,
    count: 0,
    last: 0,
  });
  const [searchData, setsearchData] = React.useState<SearchDataType>({
    searchBy: "no",
    search: "",
  });
  const [addModalOpen, addModal] = useBoolean();
  const [editModalOpen, editModal] = useBoolean();
  const [deleteModalOpen, deleteModal] = useBoolean();
  const tableSchema = useColorModeValue("blackAlpha", "gray");

  async function fetchData() {
    const result = await prestasiService.getAll({
      page: pagination.current,
      limit: 10,
      orderBy: "id",
      order: "asc",
      searchBy: searchData.searchBy,
      search: searchData.search,
      filter: { tingkat: { id: Number(tingkatId) } },
    });
    setData(result);
    setPagination(result.pagination!);
  }

  async function onAdd(values: IPrestasi) {
    const result = await prestasiService.post({
      nama: values.nama,
      poin: values.poin,
      tingkat: { id: Number(tingkatId) },
    });
    if (!result.error) {
      await Promise.all(values.bukti.map(async (bukti) => {
        await buktiService.post({
          ...bukti,
          prestasi: { id: result.data.id },
        });
      }));
      fetchData();
      addModal.off();
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onEdit(values: IPrestasi) {
    const result = await prestasiService.put({
      id: values.id,
      nama: values.nama,
      poin: values.poin,
    });
    if (!result.error) {
      await Promise.all(values.bukti.map(async (bukti) => {
        await buktiService.post(bukti);
      }));
      fetchData();
      editModal.off();
      setselectedData(null);
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onDelete(id: number) {
    const result = await prestasiService.delete(id);
    if (!result.error) {
      fetchData();
      editModal.off();
      setselectedData(null);
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pagination.current, searchData.search]);

  if (!data) return <Loading />;

  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={3} mb={3}>
          <BackButton />
          <Heading size={{ base: "sm", md: "md" }}>
            Daftar prestasi / jabatan - Tingkat {data.data[0]?.tingkat.nama}
          </Heading>
        </Flex>
        <IconButton
          hidden={user?.role !== UserRole.ADMIN}
          colorScheme="teal"
          icon={<FaPlus />}
          size={{ base: "xs", md: "sm" }}
          aria-label="Tambah prestasi"
          onClick={addModal.on} />
      </Flex>
      <Search onSearch={(value) => setsearchData(value)} options={searchOptions} />
      <TableContainer fontSize={{ base: "sm", md: "md" }}>
        <Table colorScheme={tableSchema} variant="striped">
          <Thead>
            <Tr>
              <Th w={1}>No.</Th>
              <Th>Prestasi / Jabatan</Th>
              <Th>Bobot SKP</Th>
              <Th>Dasar penilaian</Th>
              <Th hidden={user?.role !== UserRole.ADMIN}>Aksi</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.data?.map((prestasi, index) => (
              <Tr key={prestasi.id}>
                <Td>{index + 1}</Td>
                <Td>{prestasi.nama}</Td>
                <Td>{prestasi.poin}</Td>
                <Td>
                  <Flex gap={2}>
                    {prestasi.bukti.map((bukti) => (
                      <Badge key={bukti.id} colorScheme="yellow">
                        {bukti.nama}
                      </Badge>
                    ))}
                  </Flex>
                </Td>
                <Td hidden={user?.role !== UserRole.ADMIN}>
                  <Flex gap={2}>
                    <IconButton
                      colorScheme="orange"
                      icon={<FaEdit />}
                      size={{ base: "xs", md: "sm" }}
                      aria-label="Edit prestasi"
                      onClick={() => {
                        setselectedData(prestasi);
                        editModal.on();
                      }} />
                    <IconButton
                      colorScheme="red"
                      icon={<FaTrash />}
                      size={{ base: "xs", md: "sm" }}
                      aria-label="Hapus prestasi"
                      onClick={() => {
                        setselectedData(prestasi);
                        deleteModal.on();
                      }} />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationControl
        pagination={pagination}
        onPrev={() =>
          setPagination((prev) => ({
            ...prev, current: prev.current > 1
              ? prev.current - 1
              : prev.current
          }))
        }
        onNext={() =>
          setPagination((prev) => ({
            ...prev, current: prev.last !== 0 && prev.current !== prev.last
              ? prev.current + 1
              : prev.current
          }))
        } />
      <Flex flexDir={"column"}>
        <Flex alignItems={"center"} mb={2} gap={2}>
          <FaInfoCircle />
          <Text fontWeight={"semibold"}>Keterangan :</Text>
        </Flex>
        <UnorderedList>
          <ListItem>
            SK = Surat Keputusan / Keterangan
          </ListItem>
          <ListItem>
            SP = Surat Penugasan
          </ListItem>
          <ListItem>
            Dok = Dokumentasi (foto, video, dll.)
          </ListItem>
        </UnorderedList>
      </Flex>
      <AddForm
        isOpen={addModalOpen}
        onClose={addModal.off}
        onSubmit={onAdd} />
      {selectedData ? (
        <>
          <EditForm
            isOpen={editModalOpen}
            onClose={() => {
              editModal.off();
              setselectedData(null);
            }}
            onSubmit={onEdit}
            currentData={selectedData} />
          <ConfirmPrompt
            title="Yakin hapus prestasi/jabatan ini?"
            description="Setelah data ini dihapus, seluruh data dasar penilaian yang terkait akan ikut terhapus secara permanen."
            isOpen={deleteModalOpen}
            onApprove={() => onDelete(selectedData.id)}
            onReject={() => {
              deleteModal.off();
              setselectedData(null);
            }} />
        </>
      ) : null}
    </Box>
  );
}

declare type AddFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IPrestasi) => void;
};

function AddForm({
  isOpen,
  onClose,
  onSubmit,
}: AddFormProps) {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IPrestasi>();
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "bukti",
  });

  return (
    <ModalForm
      title="Tambah prestasi / jabatan"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}>
      <FormControl mt={3} isInvalid={!(!errors.nama)}>
        <FormLabel>Nama prestasi / jabatan</FormLabel>
        <Input
          {...register("nama", {
            required: "Nama prestasi / jabatan tidak boleh kosong",
          })} />
        <FormErrorMessage>
          {errors.nama?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3} isInvalid={!(!errors.poin)}>
        <FormLabel>Bobot SKP</FormLabel>
        <Input
          type="number"
          {...register("poin", {
            required: "Bobot SKP tidak boleh kosong",
          })} />
        <FormErrorMessage>
          {errors.poin?.message}
        </FormErrorMessage>
      </FormControl>
      {fields.map((field, index) => (
        <Flex key={field.id}>
          <FormControl mt={3}>
            <FormLabel>Dasar penilaian {index + 1}</FormLabel>
            <Input {...register(`bukti.${index}.nama`, {
              required: "Nama dasar penilaian tidak boleh kosong"
            })} />
          </FormControl>
          <IconButton
            alignSelf={"end"}
            ml={2}
            icon={<FaMinus />}
            aria-label="hapus bukti"
            onClick={() => remove(index)} />
        </Flex>
      ))}
      <Button
        size={{ base: "sm", md: "md" }}
        mt={3}
        variant={"outline"}
        onClick={() => append({
          nama: "",
        })}>
        <FaPlus />
        <Text ml={2}>Tambah dasar penilaian</Text>
      </Button>
    </ModalForm>
  );
}

declare type EditFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IPrestasi) => void;
  currentData: IPrestasi;
};

function EditForm({
  isOpen,
  onClose,
  onSubmit,
  currentData,
}: EditFormProps) {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
  } = useForm<IPrestasi>({
    defaultValues: {
      bukti: currentData.bukti,
    },
  });
  const {
    fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "bukti",
  });
  const toast = useToast();
  const buktiService = createBuktiService();

  async function deleteBukti(id: number, index: number) {
    const result = await buktiService.delete(id);
    if (result.error) toast({
      title: "Gagal",
      description: result.message,
      status: "error",
      duration: 2000,
    });
    else remove(index);
  }

  if (!currentData) return null;

  return (
    <ModalForm
      title="Edit prestasi / jabatan"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}>
      <Input {...register("id", { value: currentData.id })} display={"none"} />
      <FormControl mt={3} isInvalid={!(!errors.nama)}>
        <FormLabel>Nama prestasi / jabatan</FormLabel>
        <Input
          {...register("nama", {
            required: "Nama prestasi / jabatan tidak boleh kosong",
            value: currentData.nama,
          })} />
        <FormErrorMessage>
          {errors.nama?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3} isInvalid={!(!errors.poin)}>
        <FormLabel>Bobot SKP</FormLabel>
        <Input
          type="number"
          {...register("poin", {
            required: "Bobot SKP tidak boleh kosong",
            value: currentData.poin,
          })} />
        <FormErrorMessage>
          {errors.poin?.message}
        </FormErrorMessage>
      </FormControl>
      {fields.map((field, index) => (
        <Flex key={field.id}>
          <FormControl mt={3}>
            <FormLabel>Dasar penilaian {index + 1}</FormLabel>
            <Input {...register(`bukti.${index}.nama`, {
              required: "Nama dasar penilaian tidak boleh kosong"
            })} />
          </FormControl>
          <IconButton
            alignSelf={"end"}
            ml={2}
            icon={<FaMinus />}
            aria-label="hapus bukti"
            onClick={() => {
              if (field.nama !== "") deleteBukti(
                currentData.bukti[index]?.id!,
                index
              );
              else remove(index);
            }} />
        </Flex>
      ))}
      <Button
        size={{ base: "sm", md: "md" }}
        mt={3} variant={"outline"}
        onClick={() => append({
          nama: "",
          prestasi: { id: currentData.id },
        })}>
        <FaPlus />
        <Text ml={2}>Tambah dasar penilaian</Text>
      </Button>
    </ModalForm>
  );
}
