import React from "react";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import { FaUpload } from "react-icons/fa";

declare type FileSelectorProps = {
  onSelected: (file: File) => void;
  accept?: Accept;
};

export function FileSelector({ onSelected, accept = { "application/pdf": [".pdf"] } }: FileSelectorProps) {
  const onSelectedRef = React.useRef(onSelected);
  onSelectedRef.current = onSelected;
  const toast = useToast();

  const dropHandler = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length < 1) return;

    const file = acceptedFiles[0];
    onSelectedRef.current(file);
  }, []);

  const dropRejectedHandler = React.useCallback((rejectedFiles: FileRejection[]) => {
    const file = rejectedFiles[0];

    toast({
      title: "Gagal unggah lampiran",
      description: file.errors.map((x) => x.message).join(","),
      status: "error",
      duration: 5000,
    });
  }, [toast]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: dropHandler,
    onDropRejected: dropRejectedHandler,
    multiple: false,
    maxSize: 1048576,
    accept,
  });

  return (
    <Flex
      {...getRootProps()}
      flexDir={"column"}
      gap={2}
      alignItems={"center"}
      justifyContent={"center"}
      bg={"whiteAlpha.300"}
      height={200}
      borderRadius={"lg"}
      cursor={"pointer"}
      _hover={{ border: "2px dashed" }}
      border={isDragActive ? "2px dashed" : "none"}
      my={4}>
      <input {...getInputProps()} />
      <FaUpload size={28} />
      <Text fontSize={"sm"} textAlign={"center"} opacity={0.75} px={2}>
        {isDragActive
          ? "Lepaskan berkas di sini"
          : "Arahkan berkas atau klik pada area ini untuk memilih berkas"}
      </Text>
    </Flex>
  );
}
