import { Box, Flex, Input, Select, Text } from "@chakra-ui/react";
import { debounce } from "../helpers";
import React from "react";

export declare type OptionItem = {
  label: string;
  value: string;
};

export declare type SearchDataType = {
  searchBy: any;
  search: string;
};

declare type SearchProps = {
  onSearch: (value: SearchDataType) => void;
  options: OptionItem[];
};

export function Search({
  onSearch,
  options,
}: SearchProps) {
  const [searchData, setsearchData] = React.useState<SearchDataType>({
    searchBy: options[0].value,
    search: "",
  });

  React.useEffect(() => {
    onSearch(searchData);
  }, [searchData, onSearch]);

  return (
    <Box my={3}>
      <Text fontSize={"sm"} mb={2}>Cari berdasarkan</Text>
      <Flex gap={2}>
        <Select
          size={"sm"}
          width={"max-content"}
          onChange={(e) => setsearchData((prev) => ({ ...prev, searchBy: e.target.value }))}>
          {options.map((x, i) => (
            <option key={i} value={x.value}>{x.label}</option>
          ))}
        </Select>
        <Input
          width={"max-content"}
          size={"sm"}
          placeholder={`Cari ${searchData.searchBy} ...`}
          onChange={debounce(
            (e: any) => setsearchData((prev) => ({ ...prev, search: e.target.value })),
            300
          )} />
      </Flex>
    </Box>
  );
}
