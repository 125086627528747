import { api, applyAuthHeaders } from "../helpers";
import { ApiResponse, IInformasiCetak } from "../models";

interface IInformasiCetakService {
  get(): Promise<ApiResponse<IInformasiCetak>>;
  put(data: Partial<IInformasiCetak>): Promise<ApiResponse<Partial<IInformasiCetak>>>;
  uploadLogo1(file: File): Promise<ApiResponse<any>>;
  uploadLogo2(file: File): Promise<ApiResponse<any>>;
}

export default function createInformasiCetakService(): IInformasiCetakService {
  return {
    async get() {
      try {
        const response = await api.get(
          "/cetak",
          { headers: applyAuthHeaders() }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async put(data) {
      try {
        const response = await api.put(
          "/cetak",
          data,
          { headers: applyAuthHeaders() }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async uploadLogo1(file) {
      try {
        const fd = new FormData();
        fd.append("file", file);

        const response = await api.post(
          "/cetak/logo1",
          fd,
          {
            headers: {
              ...applyAuthHeaders(),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async uploadLogo2(file) {
      try {
        const fd = new FormData();
        fd.append("file", file);

        const response = await api.post(
          "/cetak/logo2",
          fd,
          {
            headers: {
              ...applyAuthHeaders(),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
  };
}
