import { useNavigate } from "react-router-dom";
import { IUser, UserRole } from "../../models";
import { useToast } from "@chakra-ui/react";
import React from "react";

declare type ProfileCheckerProps = {
  userData: IUser;
  children: any,
};

export function ProfileChecker({
  userData,
  children,
}: ProfileCheckerProps) {
  const navigate = useNavigate();
  const toast = useToast();

  React.useEffect(() => {
    if (
      userData.role === UserRole.MAHASISWA &&
      (!userData.prodi || !userData.hp)
    ) {
      toast({
        title: "Peringatan",
        description: "Harap lengkapi profil sebelum menggunakan aplikasi!",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      navigate("/app/pengaturan");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>{children}</React.Fragment>
  );
}
