import {
  FiArchive,
  FiCheckSquare,
  FiDatabase,
  FiDownload,
  FiHome,
  FiList,
  FiMessageSquare,
  FiRefreshCcw,
} from "react-icons/fi";
import { LinkItemProps } from "../components";

interface ILinkItems {
  ADMIN: LinkItemProps[],
  MAHASISWA: LinkItemProps[],
}

export const linkItems: ILinkItems = {
  ADMIN: [
    { name: "Beranda", path: "/admin", icon: FiHome },
    { name: "Tabel SKPI", path: "/admin/tabel-skpi", icon: FiDatabase },
    { name: "Pengajuan SKPI", path: "/admin/pengajuan-skpi", icon: FiCheckSquare },
    { name: "Riwayat", path: "/admin/riwayat", icon: FiRefreshCcw },
    { name: "Draft Mahasiswa", path: "/admin/draft", icon: FiArchive },
    { name: "Pesan", path: "/admin/pesan", icon: FiMessageSquare },
  ],
  MAHASISWA: [
    { name: "Beranda", path: "/app", icon: FiHome },
    { name: "Tabel SKPI", path: "/app/tabel-skpi", icon: FiDatabase },
    { name: "Transkrip SKPI", path: "/app/transkrip-skpi", icon: FiList },
    { name: "Pesan", path: "/app/pesan", icon: FiMessageSquare },
    { name: "Contoh Transkrip", path: "/app/contoh-transkrip", icon: FiDownload },
  ],
};
