import { UserRole } from "../models";
import { useCheckToken } from "../hooks";
import { Navigate, Outlet } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { linkItems } from "../helpers";

declare type ProtectedLayoutProps = {
  roles: UserRole[];
};

export function ProtectedLayout({
  roles,
}: ProtectedLayoutProps) {
  const [isTokenValid, user] = useCheckToken();

  if (isTokenValid && user && roles.includes(user.role)) return (
    <Sidebar linkItems={linkItems[user.role]}>
      <Outlet />
    </Sidebar>
  );

  return <Navigate to={isTokenValid ? "/forbidden" : "/login"} replace />;
}
