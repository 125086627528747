import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";

declare type ModalFormProps = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  children: any;
};

export function ModalForm({
  title,
  isOpen,
  onClose,
  onSubmit,
  isSubmitting,
  children,
}: ModalFormProps) {
  return (
    <Modal size={{ base: "md", md: "xl" }} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          <Button
            size={{ base: "sm", md: "md" }}
            onClick={onClose}>
            Batal
          </Button>
          <Button
            size={{ base: "sm", md: "md" }}
            ml={2}
            colorScheme="green"
            onClick={onSubmit}
            isLoading={isSubmitting}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
