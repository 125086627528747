import { Flex, IconButton, Text } from "@chakra-ui/react";
import { FaBackward, FaForward } from "react-icons/fa";
import { Pagination } from "../models";

declare type PaginationControlProps = {
  pagination: Pagination;
  onNext: () => void;
  onPrev: () => void;
};

export function PaginationControl({
  pagination,
  onNext,
  onPrev,
}: PaginationControlProps) {
  return (
    <Flex alignItems={"center"} justifyContent={"center"} gap={3} my={3}>
      <IconButton
        size={{ base: "xs", md: "sm" }}
        icon={<FaBackward />}
        aria-label="prev"
        variant={"outline"}
        onClick={onPrev} />
      <Text>{pagination.current} / {pagination.last}</Text>
      <IconButton
        size={{ base: "xs", md: "sm" }}
        icon={<FaForward />}
        aria-label="next"
        variant={"outline"}
        onClick={onNext} />
    </Flex>
  );
}
