import { Box, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { FiCheckCircle, FiRefreshCcw } from "react-icons/fi";
import { DashboardCard, Loading } from "../../components";
import React from "react";
import { IUser } from "../../models";
import { createUserService } from "../../services";
import { useCheckToken } from "../../hooks";
import { useNavigate } from "react-router-dom";

export default function DashboardPage() {
  const [, user] = useCheckToken();
  const navigate = useNavigate();
  const userService = createUserService();
  const [userData, setuserData] = React.useState<IUser[] | null>(null);

  async function fetchuser() {
    const result = await userService.getAll({
      page: 1,
      limit: 9999,
      orderBy: "nama",
      order: "asc",
    });
    setuserData(result.data);
  }

  React.useEffect(() => {
    fetchuser();
    // eslint-disable-next-line
  }, []);

  if (!userData) return <Loading />;

  return (
    <Box>
      <Text
        textAlign={{ base: "center", md: "left" }}
        mb={4}>
        Selamat datang, {user?.nama}.
      </Text>
      <Wrap spacing={6} justify={{ base: "center", md: "start" }}>
        <WrapItem>
          <DashboardCard
            label="Jumlah pengajuan saat ini"
            value={userData.filter((x) => x.diajukan && !x.disetujui).length.toString()}
            onClick={() => navigate("pengajuan-skpi")}
            Icon={FiCheckCircle} />
        </WrapItem>
        <WrapItem>
          <DashboardCard
            label="Jumlah riwayat"
            value={userData.filter((x) => x.disetujui).length.toString()}
            onClick={() => navigate("riwayat")}
            Icon={FiRefreshCcw} />
        </WrapItem>
      </Wrap>
    </Box>
  );
}
