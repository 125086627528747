import { api, applyAuthHeaders } from "../helpers";
import { ApiResponse, ILampiran } from "../models";

interface ILampiranService {
  get(id: number): Promise<ApiResponse<ILampiran>>;
  upload(file: File | null, url?: string): Promise<ApiResponse<ILampiran>>;
  delete(id: number): Promise<ApiResponse<any>>;
}

export default function createLampiranService(): ILampiranService {
  return {
    async get(id) {
      try {
        const response = await api.get(
          "/lampiran",
          {
            params: { id },
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async upload(file, url?: string) {
      try {
        const fd = new FormData();
        if (file) fd.append("file", file);
        else if (url) fd.append("url", url);
        const response = await api.post(
          "/lampiran",
          fd,
          {
            headers: {
              ...applyAuthHeaders(),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async delete(id) {
      try {
        const response = await api.delete(
          "/lampiran",
          {
            params: { id },
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
  };
}
