import { api, applyAuthHeaders } from "../helpers";
import { ApiResponse, GetAllRequest, ITranskrip } from "../models";

interface ITranskripService {
  getAll(args: GetAllRequest<ITranskrip>): Promise<ApiResponse<ITranskrip[]>>;
  get(id: number): Promise<ApiResponse<ITranskrip>>;
  post(data: Partial<ITranskrip>): Promise<ApiResponse<ITranskrip>>;
  postPersetujuan(
    id: number,
    alasanPenolakan?: string
  ): Promise<ApiResponse<ITranskrip>>;
  put(data: Partial<ITranskrip>): Promise<ApiResponse<Partial<ITranskrip>>>;
  delete(id: number): Promise<ApiResponse<any>>;
}

export default function createTranskripService(): ITranskripService {
  return {
    async getAll(args) {
      try {
        const response = await api.get(
          "/transkrip",
          {
            params: {
              ...args,
              filter: encodeURIComponent(JSON.stringify(args.filter)),
            },
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async get(id) {
      try {
        const response = await api.get(
          "/transkrip",
          {
            params: { id },
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async post(data) {
      try {
        const response = await api.post(
          "/transkrip",
          data,
          { headers: applyAuthHeaders() }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async postPersetujuan(id, alasanPenolakan) {
      try {
        const response = await api.post(
          "/transkrip/persetujuan",
          { id, alasanPenolakan },
          { headers: applyAuthHeaders() }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async put(data) {
      try {
        const response = await api.put(
          "/transkrip",
          data,
          { headers: applyAuthHeaders() }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
    async delete(id) {
      try {
        const response = await api.delete(
          "/transkrip",
          {
            params: { id },
            headers: applyAuthHeaders(),
          }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
  };
}
