import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  IconButton,
  Flex,
  Button,
  Heading,
  useBoolean,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  FormErrorMessage,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ConfirmPrompt,
  Loading,
  ModalForm,
  OptionItem,
  PaginationControl,
  Search,
  SearchDataType,
} from "../../../components";
import { createSkpiService } from "../../../services";
import React from "react";
import { ApiResponse, ISkpi, JenisSkpi, Pagination, UserRole } from "../../../models";
import { FaEdit, FaExternalLinkAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCheckToken } from "../../../hooks";

const searchOptions: OptionItem[] = [
  { label: "No", value: "no" },
  { label: "Nama", value: "nama" },
  { label: "Jenis", value: "jenis" },
];

export default function TabelSkpiPage() {
  const [, user] = useCheckToken();
  const skpiService = createSkpiService();
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = React.useState<ApiResponse<ISkpi[]> | null>(null);
  const [selectedData, setselectedData] = React.useState<ISkpi | null>(null);
  const [pagination, setPagination] = React.useState<Pagination>({
    current: 1,
    count: 0,
    last: 0,
  });
  const [searchData, setsearchData] = React.useState<SearchDataType>({
    searchBy: "no",
    search: "",
  });
  const [addModalOpen, addModal] = useBoolean();
  const [editModalOpen, editModal] = useBoolean();
  const [deleteModalOpen, deleteModal] = useBoolean();
  const tableSchema = useColorModeValue("blackAlpha", "gray");

  async function fetchData() {
    const result = await skpiService.getAll({
      page: pagination.current,
      limit: 10,
      orderBy: "no",
      order: "asc",
      searchBy: searchData.searchBy,
      search: searchData.search,
    });
    setData(result);
    setPagination(result.pagination!);
  }

  async function onAdd(values: ISkpi) {
    const result = await skpiService.post(values);
    if (!result.error) {
      fetchData();
      addModal.off();
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onEdit(values: Partial<ISkpi>) {
    const result = await skpiService.put(values);
    if (!result.error) {
      fetchData();
      editModal.off();
      setselectedData(null);
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onDelete(id: number) {
    const result = await skpiService.delete(id);
    if (!result.error) {
      fetchData();
      editModal.off();
      setselectedData(null);
    }
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [pagination.current, searchData.search]);

  if (!data) return <Loading />;

  return (
    <Box>
      <Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
        <Heading size={{ base: "sm", md: "md" }}>Tabel SKPI</Heading>
        <IconButton
          hidden={user?.role !== UserRole.ADMIN}
          colorScheme="teal"
          icon={<FaPlus />}
          size={{ base: "xs", md: "sm" }}
          aria-label="Tambah SKPI"
          onClick={addModal.on} />
      </Flex>
      <Search onSearch={(value) => setsearchData(value)} options={searchOptions} />
      <TableContainer fontSize={{ base: "sm", md: "md" }}>
        <Table colorScheme={tableSchema} variant="striped">
          <Thead>
            <Tr>
              <Th w={1}>No.</Th>
              <Th>Nama</Th>
              <Th>Jenis</Th>
              <Th>Kegiatan</Th>
              <Th hidden={user?.role !== UserRole.ADMIN}>Aksi</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.data?.map((skpi) => (
              <Tr key={skpi.id}>
                <Td>{skpi.no}</Td>
                <Td maxW={{ base: 300, md: 400, lg: "100%" }} isTruncated>{skpi.nama}</Td>
                <Td>{skpi.jenis}</Td>
                <Td>
                  <Flex gap={3}>
                    <Button
                      colorScheme="teal"
                      size={{ base: "xs", md: "sm" }}
                      onClick={() => navigate(`${skpi.id}`)}>
                      <FaExternalLinkAlt />
                      <Text ml={2}>Lihat kegiatan</Text>
                    </Button>
                  </Flex>
                </Td>
                <Td hidden={user?.role !== UserRole.ADMIN}>
                  <Flex gap={2}>
                    <IconButton
                      colorScheme="orange"
                      icon={<FaEdit />}
                      size={{ base: "xs", md: "sm" }}
                      aria-label="Edit SKPI"
                      onClick={() => {
                        setselectedData(skpi);
                        editModal.on();
                      }} />
                    <IconButton
                      colorScheme="red"
                      icon={<FaTrash />}
                      size={{ base: "xs", md: "sm" }}
                      aria-label="Hapus SKPI"
                      onClick={() => {
                        setselectedData(skpi);
                        deleteModal.on();
                      }} />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationControl
        pagination={pagination}
        onPrev={() =>
          setPagination((prev) => ({
            ...prev, current: prev.current > 1
              ? prev.current - 1
              : prev.current
          }))
        }
        onNext={() =>
          setPagination((prev) => ({
            ...prev, current: prev.last !== 0 && prev.current !== prev.last
              ? prev.current + 1
              : prev.current
          }))
        } />
      <AddForm
        isOpen={addModalOpen}
        onClose={addModal.off}
        onSubmit={onAdd} />
      {selectedData ? (
        <>
          <EditForm
            isOpen={editModalOpen}
            onClose={() => {
              editModal.off();
              setselectedData(null);
            }}
            onSubmit={onEdit}
            currentData={selectedData} />
          <ConfirmPrompt
            title="Yakin hapus SKPI ini?"
            description="Setelah tabel SKPI ini dihapus, seluruh data yang terhubung seperti kegiatan, dll. akan ikut terhapus secara permanen."
            isOpen={deleteModalOpen}
            onApprove={() => onDelete(selectedData.id)}
            onReject={() => {
              deleteModal.off();
              setselectedData(null);
            }} />
        </>
      ) : null}
    </Box>
  );
}

declare type AddFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: ISkpi) => void;
};

function AddForm({
  isOpen,
  onClose,
  onSubmit,
}: AddFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ISkpi>();

  return (
    <ModalForm
      title="Tambah SKPI"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}>
      <FormControl mt={3} isInvalid={!(!errors.no)}>
        <FormLabel>No. tabel</FormLabel>
        <Input
          type="number"
          {...register("no", {
            required: "No. tabel tidak boleh kosong",
          })} />
        <FormErrorMessage>
          {errors.no?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3} isInvalid={!(!errors.nama)}>
        <FormLabel>Nama tabel SKPI</FormLabel>
        <Input
          {...register("nama", {
            required: "Nama tabel SKPI tidak boleh kosong",
          })} />
        <FormErrorMessage>
          {errors.nama?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3} isInvalid={!(!errors.jenis)}>
        <FormLabel>Jenis</FormLabel>
        <Select
          defaultValue={JenisSkpi.PILIHAN}
          {...register("jenis")}>
          {Object.values(JenisSkpi).map((jenis, index) => (
            <option key={index} value={jenis}>{jenis}</option>
          ))}
        </Select>
      </FormControl>
    </ModalForm>
  );
}

declare type EditFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: ISkpi) => void;
  currentData: ISkpi;
};

function EditForm({
  isOpen,
  onClose,
  onSubmit,
  currentData,
}: EditFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ISkpi>();

  if (!currentData) return null;

  return (
    <ModalForm
      title="Edit SKPI"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}>
      <Input {...register("id", { value: currentData.id })} display={"none"} />
      <FormControl mt={3} isInvalid={!(!errors.no)}>
        <FormLabel>No. tabel</FormLabel>
        <Input
          type="number"
          {...register("no", {
            value: currentData.no,
          })} />
        <FormErrorMessage>
          {errors.no?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3} isInvalid={!(!errors.nama)}>
        <FormLabel>Nama tabel SKPI</FormLabel>
        <Input
          {...register("nama", {
            required: "Nama tabel SKPI tidak boleh kosong",
            value: currentData.nama,
          })} />
        <FormErrorMessage>
          {errors.nama?.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl mt={3} isInvalid={!(!errors.jenis)}>
        <FormLabel>Jenis</FormLabel>
        <Select
          defaultValue={JenisSkpi.PILIHAN}
          {...register("jenis", {
            value: currentData.jenis,
          })}>
          {Object.values(JenisSkpi).map((jenis, index) => (
            <option key={index} value={jenis}>{jenis}</option>
          ))}
        </Select>
      </FormControl>
    </ModalForm>
  );
}
