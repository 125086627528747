import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createUserService } from "../../services";
import { appStorage } from "../../helpers";
import { useCheckToken } from "../../hooks";
import { UserRole } from "../../models";
import React from "react";

declare type LoginField = {
  username: string;
  password: string;
};

export default function LoginPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const userService = createUserService();
  const [isTokenValid, user] = useCheckToken();
  const flexBg = useColorModeValue("gray.50", "gray.800");
  const boxBg = useColorModeValue("white", "gray.700");
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<LoginField>();

  React.useEffect(() => {
    if (isTokenValid && user) {
      if (user.role === UserRole.ADMIN) navigate(
        "/admin",
        { replace: true }
      );
      if (user.role === UserRole.MAHASISWA) navigate(
        "/app",
        { replace: true }
      );
    }
  }, [isTokenValid, user, navigate]);

  async function onSubmit(values: LoginField) {
    const result = await userService.postLogin(
      values.username,
      values.password
    );

    if (result.error) toast({
      title: "Login gagal",
      description: result.message,
      status: "error",
      duration: 3000,
      isClosable: true,
    });

    else {
      appStorage.set("token", result.token!);
      navigate(
        result.data.role === "ADMIN"
          ? "/admin"
          : "/app"
      );
    }
  }

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={flexBg}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"3xl"}>Silahkan masuk</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            untuk mulai mengatur SKPI Anda
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={boxBg}
          boxShadow={"lg"}
          p={8}>
          <Stack spacing={4}>
            <FormControl isInvalid={!(!errors.username)}>
              <FormLabel htmlFor="username">NIM/NIP</FormLabel>
              <Input
                placeholder="NIM/NIP"
                {...register("username", {
                  required: "NIM/NIP tidak boleh kosong",
                  minLength: { value: 13, message: "NIM/NIP tidak boleh kurang dari 13 karakter" },
                })} />
              <FormErrorMessage>
                {errors.username?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!(!errors.password)}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                type="password"
                placeholder="********"
                {...register("password", {
                  required: "Password tidak boleh kosong",
                })} />
              <FormErrorMessage>
                {errors.password?.message}
              </FormErrorMessage>
            </FormControl>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}>
              Masuk
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
