import { useBoolean } from "@chakra-ui/react";
import { ISkpi, IUser } from "../models";
import { cetak } from "../helpers";

export function useCetakSKPI() {
  const [isLoading, loading] = useBoolean();

  async function onCetak(user: IUser, skpi: ISkpi[]) {
    loading.on();
    await cetak(user, skpi);
    loading.off();
  }

  return { onCetak, isLoading };
}
