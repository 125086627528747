import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { BackButton, FileSelector, Loading } from "../../components";
import { useForm } from "react-hook-form";
import { IInformasiCetak, IUser } from "../../models";
import { createInformasiCetakService, createUserService } from "../../services";
import React from "react";
import { FiSave, FiX } from "react-icons/fi";
import { useCheckToken } from "../../hooks";
import { FaCheckCircle } from "react-icons/fa";

type TPengaturanField = IInformasiCetak & Partial<IUser>;

export default function PengaturanPage() {
  const [, user] = useCheckToken();
  const toast = useToast();
  const informasiCetakService = createInformasiCetakService();
  const userService = createUserService();
  const [pengaturanData, setpengaturanData] = React.useState<TPengaturanField | null>(null);
  const [logo, setLogo] = React.useState<{
    logo1: File | null;
    logo2: File | null;
  }>({ logo1: null, logo2: null });
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<TPengaturanField>();

  async function onSubmitInfoCetak(values: TPengaturanField) {
    if (!pengaturanData?.logo1 || !pengaturanData.logo2) {
      toast({
        title: "Simpan gagal",
        description: "Harap mengunggah logo terlebih dahulu!",
        status: "error",
        duration: 3000,
      });
      return;
    }

    const result = await informasiCetakService.put({
      judul: values.judul,
      namaWd: values.namaWd,
      nipWd: values.nipWd,
      namaWr: values.namaWr,
      nipWr: values.nipWr,
    });

    if (!result.error && (!!logo.logo1 || !!logo.logo2)) {
      if (!!logo.logo1) {
        const logo1Result = await informasiCetakService.uploadLogo1(logo.logo1!);

        if (logo1Result.error) {
          toast({
            title: "Upload logo kiri gagal!",
            status: "warning",
            duration: 3000,
          });
          return;
        }
      }
      if (!!logo.logo2) {
        const logo1Result = await informasiCetakService.uploadLogo2(logo.logo2!);

        if (logo1Result.error) {
          toast({
            title: "Upload logo kanan gagal!",
            status: "warning",
            duration: 3000,
          });
          return;
        }
      }

      fetchData();
      setLogo({ logo1: null, logo2: null });
    }

    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function onSubmitUser(values: TPengaturanField) {
    const result = await userService.put({
      id: user?.id,
      hp: values.hp,
      email: values.email,
    });
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  async function fetchData() {
    const informasiCetakResult = await informasiCetakService.get();
    const userResult = await userService.get(user?.id!);
    setpengaturanData({
      ...informasiCetakResult.data,
      ...userResult.data,
    });
  }

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (!pengaturanData) return <Loading />;

  return (
    <Box>
      <Flex alignItems={"center"} gap={3} mb={6}>
        <BackButton />
        <Heading size={{ base: "sm", md: "md" }}>
          Pengaturan
        </Heading>
      </Flex>
      <Flex
        gap={{ base: 10, lg: 4 }}
        justifyContent={"space-between"}
        flexDir={{ base: "column", lg: "row" }}>
        <Stack spacing={3} w={{ base: "full", md: "lg" }} border={"1px"} borderColor={"teal"} p={4} rounded={"lg"}>
          <Heading
            size={{ base: "sm", md: "md" }}
            textAlign={"center"}>
            Informasi Cetak SKPI
          </Heading>
          <FormControl isInvalid={!!errors.judul}>
            <FormLabel>Judul header</FormLabel>
            <Textarea
              defaultValue={pengaturanData?.judul}
              {...register("judul", {
                required: "Judul tidak boleh kosong",
              })} />
            <FormErrorMessage>{errors.judul?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.namaWd}>
            <FormLabel>Nama Wakil Dekan III FT</FormLabel>
            <Input
              defaultValue={pengaturanData?.namaWd}
              {...register("namaWd", {
                required: "Nama WD III tidak boleh kosong",
              })} />
            <FormErrorMessage>{errors.namaWd?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.nipWd}>
            <FormLabel>NIP Wakil Dekan III FT</FormLabel>
            <Input
              defaultValue={pengaturanData?.nipWd}
              {...register("nipWd", {
                required: "NIP WD III tidak boleh kosong",
              })} />
            <FormErrorMessage>{errors.nipWd?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.namaWr}>
            <FormLabel>Nama Wakil Rektor III USK</FormLabel>
            <Input
              defaultValue={pengaturanData?.namaWr}
              {...register("namaWr", {
                required: "Nama WR III tidak boleh kosong",
              })} />
            <FormErrorMessage>{errors.namaWr?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.nipWr}>
            <FormLabel>NIP Wakil Rektor III USK</FormLabel>
            <Input
              defaultValue={pengaturanData?.nipWr}
              {...register("nipWr", {
                required: "NIP WR III tidak boleh kosong",
              })} />
            <FormErrorMessage>{errors.nipWr?.message}</FormErrorMessage>
          </FormControl>
          <Flex gap={2} mt={3}>
            <Box w={"50%"}>
              <Text>Logo Kiri</Text>
              <FileSelector
                accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
                onSelected={(file) => setLogo((prev) => ({ ...prev, logo1: file }))} />
              <Flex
                hidden={!logo.logo1}
                justifyContent={"space-between"}
                borderBottom={"1px"}
                pb={1}>
                <Flex alignItems={"center"} gap={2}>
                  <FaCheckCircle color="green" size={18} />
                  <Text fontSize={"sm"}>{logo.logo1?.name}</Text>
                </Flex>
                <IconButton
                  variant={"ghost"}
                  size={"sm"}
                  icon={<FiX />}
                  aria-label="hapus berkas"
                  onClick={() => setLogo((prev) => ({ ...prev, logo1: null }))} />
              </Flex>
            </Box>
            <Box w={"50%"}>
              <Text>Logo Kanan</Text>
              <FileSelector
                accept={{ "image/png": [".png"], "image/jpeg": [".jpg", ".jpeg"] }}
                onSelected={(file) => setLogo((prev) => ({ ...prev, logo2: file }))} />
              <Flex
                hidden={!logo.logo2}
                justifyContent={"space-between"}
                borderBottom={"1px"}
                pb={1}>
                <Flex alignItems={"center"} gap={2}>
                  <FaCheckCircle color="green" size={18} />
                  <Text fontSize={"sm"}>{logo.logo2?.name}</Text>
                </Flex>
                <IconButton
                  variant={"ghost"}
                  size={"sm"}
                  icon={<FiX />}
                  aria-label="hapus berkas"
                  onClick={() => setLogo((prev) => ({ ...prev, logo2: null }))} />
              </Flex>
            </Box>
          </Flex>
          <Button
            mt={4}
            colorScheme="teal"
            isLoading={isSubmitting}
            leftIcon={<FiSave />}
            onClick={handleSubmit(onSubmitInfoCetak)}>
            Simpan
          </Button>
        </Stack>
        <Stack spacing={3} w={{ base: "full", md: "lg" }} border={"1px"} borderColor={"teal"} p={4} rounded={"lg"}>
          <Heading
            size={{ base: "sm", md: "md" }}
            textAlign={"center"}>
            Informasi Akun
          </Heading>
          <FormControl isInvalid={!!errors.hp}>
            <FormLabel>No. HP/WA aktif</FormLabel>
            <Input
              defaultValue={pengaturanData?.hp ?? ""}
              {...register("hp", {
                required: "No. HP operator tidak boleh kosong",
              })} />
            <FormErrorMessage>{errors.hp?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Alamat Email</FormLabel>
            <Input
              defaultValue={pengaturanData?.email ?? ""}
              {...register("email", {
                required: "Email operator tidak boleh kosong",
              })} />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <Button
            mt={4}
            colorScheme="teal"
            isLoading={isSubmitting}
            leftIcon={<FiSave />}
            onClick={handleSubmit(onSubmitUser)}>
            Simpan
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
}
