import DashboardPage from "./dashboard.page";
import PengajuanSkpiPage from "./pengajuan-skpi.page";
import TranskripDetailPage from "./transkrip-detail.page";
import RiwayatPage from "./riwayat.page";
import DraftPage from "./draft.page";
import EditSkpiPage from "./edit-skpi.page";
import PengaturanPage from "./pengaturan.page";
import HimbauanPage from "./himbauan.page";

export const AdminPages = {
  DashboardPage,
  PengajuanSkpiPage,
  TranskripDetailPage,
  RiwayatPage,
  DraftPage,
  EditSkpiPage,
  PengaturanPage,
  HimbauanPage,
};
