import { IconButton } from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export function BackButton({ href }: { href?: string; }) {
  const navigate = useNavigate();

  return (
    <IconButton
      icon={<FaArrowLeft />}
      aria-label="kembali"
      onClick={() => navigate(href || -1 as any)} />
  );
}
