declare type Config = {
  BASE_URL: string;
  APP_VERSION: string;
};

declare type ConfigKeys = keyof Config;

const config: Config = {
  BASE_URL: "/api",
  // BASE_URL: "/api",
  APP_VERSION: "1.0.0",
};

// eslint-disable-next-line
export default {
  get(key: ConfigKeys) {
    return config[key];
  },
};

