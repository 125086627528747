import jsPDF from "jspdf";
import { IInformasiCetak, ISkpi, IUser, JenisSkpi } from "../models";
import autoTable from "jspdf-autotable";
import { downloadFrom, parseFilepath, parseSkpiGrade, toFormattedDate, toRomanString } from "./utils";
import { createInformasiCetakService } from "../services";
import { PDFDocument } from "pdf-lib";

const fullWidth = 210;
const fullHeight = 297;

function createCetakTranskrip(
  user: IUser,
  skpi: ISkpi[],
  informasiCetak: IInformasiCetak,
) {
  const ttdPosition = 220;
  const doc = new jsPDF();
  const centerText = (text: string) => (fullWidth / 2) - (doc.getTextWidth(text) / 2);

  doc.setFont("helvetica", "", "bold");
  doc.setFontSize(11);
  // doc.text("KEMENTERIAN PENDIDIKAN, KEBUDAYAAN, RISET, DAN TEKNOLOGI", 43, 20);
  doc.text(informasiCetak.judul, centerText(informasiCetak.judul) + 4, 20);
  doc.text("UNIVERSITAS SYIAH KUALA", 83, 26);
  doc.text("FAKULTAS TEKNIK", 91, 32);
  doc.text("RINCIAN TRANSKRIP SURAT KETERANGAN PENDAMPING IJAZAH", 47, 42);

  doc.setFont("helvetica", "", "normal");
  doc.setFontSize(10);
  doc.text("Nama", 75, 54);
  doc.text("NPM", 75, 60);
  doc.text("Program Studi", 75, 66);

  doc.setFont("helvetica", "", "bold");
  doc.text(`: ${user.nama}`, 105, 54);
  doc.text(`: ${user.username}`, 105, 60);
  doc.text(`: ${user.jenjang} - ${user.prodi}`, 105, 66);

  autoTable(doc, {
    margin: { left: 30, right: 20, top: 20, bottom: 20 },
    startY: 76,
    theme: "grid",
    showHead: "firstPage",
    showFoot: "lastPage",
    styles: {
      fontSize: 9,
      textColor: "black",
      lineColor: "black",
      valign: "middle",
      cellPadding: 1.5,
    },
    pageBreak: "auto",
    rowPageBreak: "avoid",
    columnStyles: {
      0: { halign: "center", cellWidth: 8 },
      3: { halign: "center", cellWidth: 22 },
      4: { halign: "center", cellWidth: 18 },
      5: { halign: "center", cellWidth: 18 },
      6: { halign: "center", cellWidth: 22 },
    },
    body: [
      [
        { content: "No", styles: { fontStyle: "bold" } },
        { content: "Nama Kegiatan", colSpan: 2, styles: { fontStyle: "bold", halign: "center" } },
        { content: "Tingkat / Tempat", styles: { fontStyle: "bold" } },
        { content: "Semester-Tahun", styles: { fontStyle: "bold" } },
        { content: "Nilai skp", styles: { fontStyle: "bold" } },
        { content: "Bukti Fisik (Nomor)", styles: { fontStyle: "bold" } },
      ],
      [
        { content: "A", styles: { fontStyle: "bold" } },
        { content: "Wajib Universitas", colSpan: 2, styles: { fontStyle: "bold", halign: "center" } }
      ],
      ...user.transkrip
        .filter((x) => x.skpi.no === 1)
        .map((x, i) => [
          { content: "" },
          { content: `${i + 1})`, styles: { halign: "right", cellWidth: 8, lineWidth: { left: 0.1, top: 0.1, bottom: 0.1, right: 0 } } },
          { content: x.nama, styles: { lineWidth: { left: 0, top: 0.1, bottom: 0.1, right: 0.1 } } },
          { content: x.tingkat.nama },
          { content: `${toRomanString(x.semester)}-${x.tahun}` },
          { content: x.prestasi.poin },
          { content: `${user.transkrip.filter((t) => t.skpi.no < x.skpi.no).length + i + 1} (${x.skpi.no}-${x.kegiatan.no})` },
        ]),
      [
        { content: "" },
        { content: "Jumlah", colSpan: 2, styles: { halign: "center" } },
        { content: "" },
        { content: "" },
        { content: user.transkrip.filter((x) => x.skpi.no === 1).reduce((a, b) => a + b.prestasi.poin, 0), styles: { fontStyle: "bold" } },
      ],
      [
        { content: "" },
        { content: "{{breakPage}}", colSpan: 2 },
      ],
      [
        { content: "B", styles: { fontStyle: "bold" } },
        { content: "Pilihan", colSpan: 2, styles: { fontStyle: "bold", halign: "center" } }
      ],
      [
        { content: "1" },
        { content: skpi.find((x) => x.no === 2)!.nama, colSpan: 2 },
      ],
      ...user.transkrip
        .filter((x) => x.skpi.no === 2)
        .map((x, i) => [
          { content: "" },
          { content: `${i + 1})`, styles: { halign: "right", cellWidth: 8, lineWidth: { left: 0.1, top: 0.1, bottom: 0.1, right: 0 } } },
          { content: x.nama, styles: { lineWidth: { left: 0, top: 0.1, bottom: 0.1, right: 0.1 } } },
          { content: x.tingkat.nama },
          { content: `${toRomanString(x.semester)}-${x.tahun}` },
          { content: x.prestasi.poin },
          { content: `${user.transkrip.filter((t) => t.skpi.no < x.skpi.no).length + i + 1} (${x.skpi.no}-${x.kegiatan.no})` },
        ]),
      [
        { content: "" },
        { content: "Jumlah", colSpan: 2, styles: { halign: "center" } },
        { content: "" },
        { content: "" },
        { content: user.transkrip.filter((x) => x.skpi.no === 2).reduce((a, b) => a + b.prestasi.poin, 0), styles: { fontStyle: "bold" } },
      ],
      [
        { content: "" },
        { content: "", colSpan: 2 },
      ],
      [
        { content: "2" },
        { content: skpi.find((x) => x.no === 3)!.nama, colSpan: 2 },
      ],
      ...user.transkrip
        .filter((x) => x.skpi.no === 3)
        .map((x, i) => [
          { content: "" },
          { content: `${i + 1})`, styles: { halign: "right", cellWidth: 8, lineWidth: { left: 0.1, top: 0.1, bottom: 0.1, right: 0 } } },
          { content: x.nama, styles: { lineWidth: { left: 0, top: 0.1, bottom: 0.1, right: 0.1 } } },
          { content: x.tingkat.nama },
          { content: `${toRomanString(x.semester)}-${x.tahun}` },
          { content: x.prestasi.poin },
          { content: `${user.transkrip.filter((t) => t.skpi.no < x.skpi.no).length + i + 1} (${x.skpi.no}-${x.kegiatan.no})` },
        ]),
      [
        { content: "" },
        { content: "Jumlah", colSpan: 2, styles: { halign: "center" } },
        { content: "" },
        { content: "" },
        { content: user.transkrip.filter((x) => x.skpi.no === 3).reduce((a, b) => a + b.prestasi.poin, 0), styles: { fontStyle: "bold" } },
      ],
      [
        { content: "" },
        { content: "", colSpan: 2 },
      ],
      [
        { content: "3" },
        { content: skpi.find((x) => x.no === 4)!.nama, colSpan: 2 },
      ],
      ...user.transkrip
        .filter((x) => x.skpi.no === 4)
        .map((x, i) => [
          { content: "" },
          { content: `${i + 1})`, styles: { halign: "right", cellWidth: 8, lineWidth: { left: 0.1, top: 0.1, bottom: 0.1, right: 0 } } },
          { content: x.nama, styles: { lineWidth: { left: 0, top: 0.1, bottom: 0.1, right: 0.1 } } },
          { content: x.tingkat.nama },
          { content: `${toRomanString(x.semester)}-${x.tahun}` },
          { content: x.prestasi.poin },
          { content: `${user.transkrip.filter((t) => t.skpi.no < x.skpi.no).length + i + 1} (${x.skpi.no}-${x.kegiatan.no})` },
        ]),
      [
        { content: "" },
        { content: "Jumlah", colSpan: 2, styles: { halign: "center" } },
        { content: "" },
        { content: "" },
        { content: user.transkrip.filter((x) => x.skpi.no === 4).reduce((a, b) => a + b.prestasi.poin, 0), styles: { fontStyle: "bold" } },
      ],
      [
        { content: "" },
        { content: "", colSpan: 2 },
      ],
      [
        { content: "4" },
        { content: skpi.find((x) => x.no === 5)!.nama, colSpan: 2 },
      ],
      ...user.transkrip
        .filter((x) => x.skpi.no === 5)
        .map((x, i) => [
          { content: "" },
          { content: `${i + 1})`, styles: { halign: "right", cellWidth: 8, lineWidth: { left: 0.1, top: 0.1, bottom: 0.1, right: 0 } } },
          { content: x.nama, styles: { lineWidth: { left: 0, top: 0.1, bottom: 0.1, right: 0.1 } } },
          { content: x.tingkat.nama },
          { content: `${toRomanString(x.semester)}-${x.tahun}` },
          { content: x.prestasi.poin },
          { content: `${user.transkrip.filter((t) => t.skpi.no < x.skpi.no).length + i + 1} (${x.skpi.no}-${x.kegiatan.no})` },
        ]),
      [
        { content: "" },
        { content: "Jumlah", colSpan: 2, styles: { halign: "center" } },
        { content: "" },
        { content: "" },
        { content: user.transkrip.filter((x) => x.skpi.no === 5).reduce((a, b) => a + b.prestasi.poin, 0), styles: { fontStyle: "bold" } },
      ],
      [
        { content: "" },
        { content: "{{breakPage}}", colSpan: 2 },
      ],
      [
        { content: "5" },
        { content: skpi.find((x) => x.no === 6)!.nama, colSpan: 2 },
      ],
      ...user.transkrip
        .filter((x) => x.skpi.no === 6)
        .map((x, i) => [
          { content: "" },
          { content: `${i + 1})`, styles: { halign: "right", cellWidth: 8, lineWidth: { left: 0.1, top: 0.1, bottom: 0.1, right: 0 } } },
          { content: x.nama, styles: { lineWidth: { left: 0, top: 0.1, bottom: 0.1, right: 0.1 } } },
          { content: x.tingkat.nama },
          { content: `${toRomanString(x.semester)}-${x.tahun}` },
          { content: x.prestasi.poin },
          { content: `${user.transkrip.filter((t) => t.skpi.no < x.skpi.no).length + i + 1} (${x.skpi.no}-${x.kegiatan.no})` },
        ]) as any,
      [
        { content: "" },
        { content: "Jumlah", colSpan: 2, styles: { halign: "center" } },
        { content: "" },
        { content: "" },
        { content: user.transkrip.filter((x) => x.skpi.no === 6).reduce((a, b) => a + b.prestasi.poin, 0), styles: { fontStyle: "bold" } },
      ],
      [
        { content: "Jumlah Total", colSpan: 3, styles: { fontStyle: "bold" } },
        { content: "" },
        { content: "" },
        { content: user.transkrip.reduce((a, b) => a + b.prestasi.poin, 0), styles: { fontStyle: "bold" } },
      ]
    ],
    willDrawCell: (x) => {
      if (
        x.cell.text[0] === "{{breakPage}}" &&
        x.cell.y > ttdPosition - 20
      ) x.settings.margin.bottom = fullHeight - ttdPosition + 35;
      if (x.cell.text[0] === "{{breakPage}}") x.cell.text[0] = "";
    }
  });

  let finalY = (doc as any).lastAutoTable.finalY;

  if (finalY - 20 > ttdPosition) {
    doc.addPage();
    finalY = 0;
  }

  doc.setFont("helvetica", "", "normal");
  doc.setFontSize(11);
  doc.text(`Banda Aceh, ${toFormattedDate(new Date(), true)}`, 120, finalY + 20);
  doc.text("Dekan", 120, finalY + 25);
  doc.text("Wakil Dekan Kemahasiswaan, Alumni", 120, finalY + 30);
  doc.text("dan Kemitraan,", 120, finalY + 35);
  doc.text(informasiCetak.namaWd, 120, finalY + 60);
  doc.text(`NIP. ${informasiCetak.nipWd}`, 120, finalY + 65);

  return doc;
}

function createCetakRekap(
  user: IUser,
  skpi: ISkpi[],
  informasiCetak: IInformasiCetak,
) {
  const doc = new jsPDF();
  const centerText = (text: string) => (fullWidth / 2) - (doc.getTextWidth(text) / 2);

  const border = require("../assets/images/border.jpg");
  // const logo = require("../assets/images/logo.png");
  // const logo2 = require("../assets/images/logo2.png");
  const logo1 = parseFilepath(informasiCetak.logo1);
  const logo2 = parseFilepath(informasiCetak.logo2);

  doc.addImage({ imageData: border, x: 8, y: 8, width: fullWidth - 16, height: fullHeight - 16 });
  doc.addImage({ imageData: logo1, x: 31, y: 31, width: 18, height: 18 });
  doc.addImage({ imageData: logo2, x: fullWidth - 15 - 31, y: 31, width: 18, height: 18 });

  doc.setFont("helvetica", "", "bold");
  doc.setFontSize(9);
  // doc.text("KEMENTERIAN PENDIDIKAN, KEBUDAYAAN, RISET, DAN TEKNOLOGI", 52, 37);
  doc.text(informasiCetak.judul, centerText(informasiCetak.judul), 37);
  doc.text("UNIVERSITAS SYIAH KUALA", 84, 43);
  doc.text("FAKULTAS TEKNIK", 91, 49);
  doc.text("TRANSKRIP SURAT KETERANGAN PENDAMPING IJAZAH", 61, 59);

  doc.setFont("helvetica", "", "normal");
  doc.setFontSize(8);
  doc.text("Nama", 62, 67);
  doc.text("NPM", 62, 73);
  doc.text("Program Studi", 62, 79);

  doc.setFont("helvetica", "", "bold");
  doc.text(`: ${user.nama}`, 90, 67);
  doc.text(`: ${user.username}`, 90, 73);
  doc.text(`: ${user.jenjang} - ${user.prodi}`, 90, 79);

  autoTable(doc, {
    margin: {
      left: 35,
      right: 35,
      top: 20,
      bottom: 20,
    },
    startY: 84,
    theme: "grid",
    showHead: "firstPage",
    showFoot: "lastPage",
    styles: {
      fontSize: 8,
      textColor: "black",
      lineColor: "black",
      valign: "middle",
      cellPadding: 1.5,
    },
    headStyles: {
      halign: "center",
      fillColor: "white",
      lineColor: "black",
      lineWidth: 0.1,
      minCellHeight: 9,
    },
    columnStyles: {
      0: { halign: "center", fontStyle: "bold" },
      2: { halign: "center" },
    },
    head: [
      ["No", "Kriteria Kegiatan", "Nilai SKP"],
    ],
    body: [
      [
        { content: "A" },
        { content: "Wajib Universitas", colSpan: 2 },
      ],
      ...user.transkrip
        .filter((x) => x.skpi.jenis === JenisSkpi.WAJIB)
        .map((x, i) => ["", `${i + 1}. ${x.kegiatan.nama}`, x.prestasi.poin]),
      [
        { content: "B" },
        { content: "Pilihan", colSpan: 2 },
      ],
      ...skpi.filter((x) => x.jenis !== JenisSkpi.WAJIB).map((x, i) => [
        "",
        `${i + 1}. ${x.nama}`,
        user.transkrip
          .filter((t) => t.skpi.id === x.id)
          .reduce((a, b) => a + b.prestasi.poin, 0)
      ]),
      [
        { content: "Jumlah Perolehan SKP", colSpan: 2, styles: { fontStyle: "bold" } },
        { content: user.transkrip.reduce((a, b) => a + b.prestasi.poin, 0), styles: { fontStyle: "bold" } },
      ],
      [
        { content: "Predikat", colSpan: 2, styles: { fontStyle: "bold" } },
        { content: parseSkpiGrade(user.transkrip.reduce((a, b) => a + b.prestasi.poin, 0)), styles: { fontStyle: "bold" } },
      ],
    ],
  });

  let finalY = (doc as any).lastAutoTable.finalY;

  doc.setFont("helvetica", "", "normal");
  doc.setFontSize(9);
  doc.text(`Banda Aceh, ${toFormattedDate(new Date(), true)}`, 120, finalY + 10);
  doc.text("Dekan", 120, finalY + 15);
  doc.text("Wakil Dekan Kemahasiswaan, Alumni", 120, finalY + 20);
  doc.text("dan Kemitraan,", 120, finalY + 25);
  doc.text(informasiCetak.namaWd, 120, finalY + 45);
  doc.text(`NIP. ${informasiCetak.nipWd}`, 120, finalY + 50);

  doc.text("Rektor", 35, finalY + 15);
  doc.text("Wakil Rektor Bidang Kemahasiswaan", 35, finalY + 20);
  doc.text("dan Kewirausahaan,", 35, finalY + 25);
  doc.text(informasiCetak.namaWr, 35, finalY + 45);
  doc.text(`NIP. ${informasiCetak.nipWr}`, 35, finalY + 50);

  doc.text("Catatan:", 35, finalY + 70);
  doc.text("Predikat SKPI S1/D3:", 35, finalY + 75);
  doc.text("Sangat Baik", 35, finalY + 80);
  doc.text("Baik", 35, finalY + 85);
  doc.text("Cukup", 35, finalY + 90);

  doc.text("= > 251", 90, finalY + 80);
  doc.text("= 151 - 250", 90, finalY + 85);
  doc.text("= 45 - 150", 90, finalY + 90);

  return doc;
}

export function cetak(user: IUser, skpi: ISkpi[]): Promise<void> {
  return new Promise(async (resolve) => {
    const informasiCetakService = createInformasiCetakService();
    const informasiCetak = await informasiCetakService.get().then((res) => res.data);

    const rekapBuffer = createCetakRekap(user, skpi, informasiCetak).output("arraybuffer");
    const transkripBuffer = createCetakTranskrip(user, skpi, informasiCetak).output("arraybuffer");

    const mergedPdf = await PDFDocument.create();

    const rekapPdf = await PDFDocument.load(rekapBuffer);
    await mergedPdf.copyPages(rekapPdf, rekapPdf.getPageIndices()).then(
      (pages) => pages.forEach((page) => mergedPdf.addPage(page))
    );

    const transkripPdf = await PDFDocument.load(transkripBuffer);
    await mergedPdf.copyPages(transkripPdf, transkripPdf.getPageIndices()).then(
      (pages) => pages.forEach((page) => mergedPdf.addPage(page))
    );

    const mergedPdfFile = await mergedPdf.save();
    const blob = new Blob([mergedPdfFile], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);

    downloadFrom(url, `transkrip_SKPI_${user.username}.pdf`);
    resolve();
  });
}
