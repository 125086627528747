import { Avatar, Box, Flex, IconButton, Input, Spinner, Text, useColorModeValue, useToast } from "@chakra-ui/react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Loading } from "../../../components";
import { FiArrowLeft, FiSend } from "react-icons/fi";
import React from "react";
import { createPesanService } from "../../../services";
import { useCheckToken } from "../../../hooks";
import { Form, useForm } from "react-hook-form";
import { UserRole, IRuangPesan, IPesan } from "../../../models";
import { toFormattedDate } from "../../../helpers";

export default function PesanDetailPage() {
  const navigate = useNavigate();
  const toast = useToast();
  const [, user] = useCheckToken();
  const { ruangPesanId } = useParams();
  const [chats, setchats] = React.useState<IPesan[] | null>(null);
  const [loading, setloading] = React.useState<boolean>(false);
  const [sendLoading, setsendLoading] = React.useState<boolean>(false);
  const borderColor = useColorModeValue('gray.400', 'gray.700');
  const pesanService = createPesanService();

  const location = useLocation();
  let ruangPesan = useOutletContext<IRuangPesan | null>();
  if (!ruangPesan) ruangPesan = location.state?.ruangPesan || null;

  const {
    control,
    handleSubmit,
    register,
    resetField,
  } = useForm<{ text: string; }>({ defaultValues: { text: "" } });

  async function fetchChats() {
    setloading(true);
    const result = await pesanService.getAllPesan(ruangPesanId!);
    if (result.error) {
      navigate(user?.role === UserRole.ADMIN ? "/admin/pesan" : "/app/pesan");
      return;
    }
    setchats(result.data);
    setloading(false);
  }

  async function sendMessage(values: { text: string; }) {
    if (!values.text) return;

    setsendLoading(true);
    const result = await pesanService.send(
      user?.role === UserRole.ADMIN
        ? { userId: ruangPesan?.user.id, pesan: values.text }
        : { pesan: values.text }
    );
    setsendLoading(false);
    if (result.error) {
      toast({
        title: "Gagal mengirim pesan",
        description: result.message,
        status: "error",
        duration: 3000,
      });
      return;
    }

    resetField("text");
    fetchChats();
  }

  React.useEffect(() => {
    fetchChats();
    if (!ruangPesan) navigate(`/${user?.role === UserRole.ADMIN ? "admin" : "app"}/pesan`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruangPesanId]);

  if (!chats) return <Loading />;

  return (
    <Flex
      pl={{ base: 0, md: 4 }}
      flexDir={"column"}
      w={"full"}>
      <Flex alignItems={"center"} gap={{ base: 2, md: 0 }} justifyContent={"space-between"}>
        <IconButton
          onClick={() => navigate(user?.role === UserRole.ADMIN ? "/admin/pesan" : "/app/pesan")}
          icon={<FiArrowLeft />}
          aria-label="Tutup" />
        <Flex
          textAlign={"right"}
          alignItems={"center"}
          gap={2}>
          <Box>
            <Text fontSize={"sm"} fontWeight={"bold"}>
              {user?.role === UserRole.ADMIN ? ruangPesan?.user?.nama : ruangPesan?.admin.nama}
            </Text>
            {user?.role === UserRole.ADMIN && (
              <Text fontSize={"xs"}>{ruangPesan?.user?.username}</Text>
            )}
          </Box>
          <Avatar
            size={"sm"}
            name={
              user?.role !== UserRole.ADMIN
                ? ruangPesan?.admin.nama
                : ruangPesan?.user.nama
            }
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection={"column-reverse"}
        overflowY={"auto"}
        border={"1px"}
        borderColor={borderColor}
        borderRadius={"lg"}
        h={"70vh"}
        w={"full"}
        mt={2}
        p={4}>
        {loading ? (
          <Flex h={"full"} alignItems={"center"} justifyContent={"center"}>
            <Spinner />
          </Flex>
        ) : (
          <>
            {chats.map((chat) => (
              <ChatBubble
                key={chat.id}
                chat={chat}
                isOwned={user?.username === chat.pengirim.username}
              />
            ))}
          </>
        )}
      </Flex>
      <Form control={control}>
        <Flex alignItems={"center"} mt={2} gap={2}>
          <Input {...register("text")} placeholder="Tulis pesan disini ..." />
          <IconButton
            type="submit"
            icon={<FiSend />}
            aria-label="Kirim"
            colorScheme="green"
            isLoading={sendLoading}
            onClick={handleSubmit(sendMessage)} />
        </Flex>
      </Form>
    </Flex>
  );
}

function ChatBubble({
  chat,
  isOwned,
}: {
  chat: IPesan;
  isOwned: boolean;
}) {
  const ownedBubbleColor = useColorModeValue('teal.200', 'teal.700');
  const unOwnedBubbleColor = useColorModeValue('cyan.200', 'cyan.700');

  return (
    <Flex
      alignSelf={isOwned ? "end" : "start"}
      flexDir={isOwned ? "row-reverse" : "row"}
      alignItems={"end"}
      gap={2}
      my={2}
      maxW={"xl"}
    >
      <Avatar size={"xs"} name={chat.pengirim.nama} />
      <Flex
        py={1}
        px={2}
        flexDir={"column"}
        rounded={"xl"}
        roundedBottomLeft={isOwned ? "xl" : 0}
        roundedBottomRight={!isOwned ? "xl" : 0}
        background={
          isOwned
            ? ownedBubbleColor
            : unOwnedBubbleColor
        }
      >
        <Text fontSize={"sm"}>
          {chat.isi}
        </Text>
        <Text fontSize={"x-small"} mt={2} alignSelf={"end"}>
          {toFormattedDate(chat.dikirimPada)}
        </Text>
      </Flex>
    </Flex>
  );
}
