import { Flex, Spinner, Text } from "@chakra-ui/react";

export function Loading(props: any) {
  return (
    <Flex
      flexDir={"column"}
      gap={2}
      alignItems={"center"}
      justifyContent={"center"}
      inset={"0"}
      position={"absolute"}
      {...props}>
      <Spinner />
      <Text>Loading...</Text>
    </Flex>
  );
}
