import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { createUserService } from "../../services";
import { Controller, useForm } from "react-hook-form";
import { useCheckToken } from "../../hooks";
import { BackButton, Loading } from "../../components";
import { FiSave } from "react-icons/fi";
import React from "react";
import { IUser, Jenjang } from "../../models";
import { Select } from "chakra-react-select";

declare type ProfileField = {
  jenjang: Jenjang;
  prodi: string;
  hp: string;
  email: string;
};

export default function PengaturanPage() {
  const [, user] = useCheckToken();
  const toast = useToast();
  const userService = createUserService();
  const [userData, setuserData] = React.useState<IUser | null>(null);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<ProfileField>();

  async function fetchUser() {
    const result = await userService.get(user?.id!);
    setuserData(result.data);
  }

  async function onSubmit(values: ProfileField) {
    const result = await userService.put({
      id: user?.id,
      ...values,
    });
    if (!result.error) fetchUser();
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  React.useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  if (!userData) return <Loading />;

  return (
    <Box>
      <Flex alignItems={"center"} gap={3} mb={6}>
        <BackButton />
        <Heading size={{ base: "sm", md: "md" }}>
          Pengaturan
        </Heading>
      </Flex>
      <Stack spacing={3} w={{ base: "full", md: "lg" }}>
        <Heading
          size={{ base: "sm", md: "md" }}
          textAlign={"center"}>
          Profil Mahasiswa
        </Heading>
        <FormControl>
          <FormLabel>Nama</FormLabel>
          <Input value={user?.nama} disabled />
        </FormControl>
        <FormControl>
          <FormLabel>NIM</FormLabel>
          <Input value={user?.username} disabled />
        </FormControl>
        <FormControl isInvalid={!!errors.jenjang}>
          <FormLabel>Jenjang Pendidikan</FormLabel>
          <Controller
            control={control}
            name="jenjang"
            rules={{
              required: "Silahkan pilih jenjang pendidikan",
            }}
            render={({ field }) => (
              <Select
                placeholder="Pilih jenjang pendidikan"
                options={Object.values(Jenjang).map((x) => ({
                  label: x,
                  value: x,
                }))}
                defaultValue={{
                  label: userData.jenjang,
                  value: userData.jenjang,
                }}
                onChange={(val) => {
                  field.onChange(val?.value);
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.jenjang?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.prodi}>
          <FormLabel>Program Studi</FormLabel>
          <Input
            placeholder="Program Studi (cth: Teknik Komputer)"
            defaultValue={userData.prodi!}
            {...register("prodi", {
              required: "Program Studi tidak boleh kosong",
            })} />
          <FormErrorMessage>{errors.prodi?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.hp}>
          <FormLabel>Nomor HP/WA aktif</FormLabel>
          <Input
            placeholder="No. HP (cth: 0812xxxxxxxx)"
            defaultValue={userData.hp!}
            {...register("hp", {
              pattern: /[0-9]/,
              required: "Nomor HP tidak boleh kosong",
            })} />
          <FormErrorMessage>{errors.hp?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>Alamat Email</FormLabel>
          <Input
            placeholder="Email (cth: nuzul@gmail.com)"
            defaultValue={userData.email!}
            {...register("email", {
              required: "Email tidak boleh kosong",
            })} />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          leftIcon={<FiSave />}
          onClick={handleSubmit(onSubmit)}>
          Simpan
        </Button>
      </Stack>
    </Box>
  );
}
