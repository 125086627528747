import { api, applyAuthHeaders } from "../helpers";
import { ApiResponse, IHimbauan } from "../models";

interface IHimbauanService {
  get(): Promise<ApiResponse<IHimbauan>>;
  post(data: Partial<IHimbauan>): Promise<ApiResponse<IHimbauan>>;
}

export default function createHimbauanService(): IHimbauanService {
  return {
    async get() {
      try {
        const response = await api.get(
          "/himbauan",
          { headers: applyAuthHeaders() },
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error.message ?? "Unknown error",
        };
      }
    },
    async post(data) {
      try {
        const response = await api.post(
          "/himbauan",
          { id: 1, ...data },
          { headers: applyAuthHeaders() }
        );
        return response.data;
      } catch (error: any) {
        return {
          error: true,
          message: error?.message ?? "Unknown error",
        };
      }
    },
  };
}
