import { useBoolean, useToast } from "@chakra-ui/react";
import { createPesanService } from "../services";
import { useNavigate } from "react-router-dom";

export function useCreateMessage() {
  const navigate = useNavigate();
  const toast = useToast();
  const [isChatLoading, chatLoading] = useBoolean();
  const pesanService = createPesanService();

  async function createMessage(userId: number) {
    chatLoading.on();
    const result = await pesanService.create(userId);
    chatLoading.off();

    if (result.error) {
      toast({
        title: "Gagal membuat pesan",
        description: result.message,
        status: "error",
        duration: 3000,
      });
      return;
    }

    navigate(`/admin/pesan/${result.data.id}`, { state: { ruangPesan: result.data } });
  }

  return { createMessage, isChatLoading };
}
