import DashboardPage from "./dashboard.page";
import FormulirSkpiPage from "./formulir-skpi.page";
import TranskripPage from "./transkrip.page";
import EditSkpiPage from "./edit-skpi.page";
import PengaturanPage from "./pengaturan.page";
import ContohTranskripPage from "./contoh-transkrip.page";

export const UserPages = {
  DashboardPage,
  FormulirSkpiPage,
  TranskripPage,
  EditSkpiPage,
  PengaturanPage,
  ContohTranskripPage,
};
