import React from "react";
import {
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { pdfjs, Document, Page } from "react-pdf";
import { ILampiran } from "../../models";
import { FaBackward, FaDownload, FaForward } from "react-icons/fa";
import { parseFilepath } from "../../helpers";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = "/scripts/pdf.worker.min.js";

declare type PdfViewerProps = {
  lampiran: ILampiran | null;
  isOpen: boolean;
  onClose: () => void;
};

export function PdfViewer({
  lampiran,
  isOpen,
  onClose,
}: PdfViewerProps) {
  const [numPages, setNumPages] = React.useState<number>();
  const [pageNumber, setPageNumber] = React.useState<number>(1);

  if (!lampiran) return null;

  return (
    <Modal
      size={"4xl"}
      isOpen={isOpen}
      onClose={() => {
        setPageNumber(1);
        onClose();
      }}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{lampiran.nama}</ModalHeader>
        <ModalBody>
          <Document
            file={{ url: parseFilepath(lampiran.path!) }}
            loading={<Spinner />}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
            <Page scale={1} pageNumber={pageNumber} />
          </Document>
          <Text mt={4}>
            Halaman {pageNumber} dari {numPages || "--"}
          </Text>
        </ModalBody>
        <ModalFooter>
          <Flex w={"100%"} justifyContent={"space-between"}>
            <Flex gap={3}>
              <IconButton
                hidden={pageNumber <= 1}
                icon={<FaBackward />}
                size={{ base: "sm", md: "md" }}
                colorScheme="teal"
                onClick={() => setPageNumber((prev) => prev - 1)}
                aria-label="prev page" />
              <IconButton
                hidden={!!numPages && pageNumber >= numPages}
                icon={<FaForward />}
                size={{ base: "sm", md: "md" }}
                colorScheme="teal"
                onClick={() => setPageNumber((prev) => prev + 1)}
                aria-label="next page" />
            </Flex>
            <Flex gap={3}>
              <IconButton
                as={"a"}
                icon={<FaDownload />}
                size={{ base: "sm", md: "md" }}
                colorScheme="teal"
                aria-label="unduh lampiran"
                href={parseFilepath(lampiran.path!)}
                target="_blank"
                download={lampiran.nama} />
              <Button
                size={{ base: "sm", md: "md" }}
                onClick={() => {
                  setPageNumber(1);
                  onClose();
                }}>
                Tutup
              </Button>
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
