export enum JenisSkpi {
  WAJIB = "WAJIB",
  PILIHAN = "PILIHAN"
}

export interface ISkpi {
  id: number;
  no: number;
  nama: string;
  jenis: JenisSkpi;
  kegiatan: IKegiatan[];
}

export interface IKegiatan {
  id: number;
  no: number;
  nama: string;
  skpi: Partial<ISkpi>;
  tingkat: ITingkat[];
}

export interface ITingkat {
  id: number;
  nama: string;
  kegiatan: Partial<IKegiatan>;
  prestasi: IPrestasi[];
}

export interface IPrestasi {
  id: number;
  nama: string;
  poin: number;
  tingkat: Partial<ITingkat>;
  bukti: Partial<IBukti>[];
}

export interface IBukti {
  id: number;
  nama: string;
  prestasi: Partial<IPrestasi>;
}
