import { IBukti, IKegiatan, IPrestasi, ISkpi, ITingkat } from "./skpi";
import { IUser } from "./user";

export interface ILampiran {
  id?: number;
  nama?: string;
  path?: string;
  url?: string;
}

export interface ITranskrip {
  id: number;
  no: number;
  nama: string;
  semester: number;
  tahun: number;
  disetujui: boolean;
  alasanPenolakan: string | null;
  user: Partial<IUser>;
  skpi: ISkpi;
  kegiatan: IKegiatan;
  tingkat: ITingkat;
  prestasi: IPrestasi;
  bukti: IBukti;
  lampiran: ILampiran;
}

export enum SkpiGrade {
  A = "Sangat Baik",
  B = "Baik",
  C = "Cukup",
  D = "Tidak Lulus",
}

export interface IInformasiCetak {
  namaWd: string;
  nipWd: string;
  namaWr: string;
  nipWr: string;
  logo1: string;
  logo2: string;
  judul: string;
};
