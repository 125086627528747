import axios from "axios";
import config from "./config";
import { appStorage } from ".";

const defaultHeaders = {
  "Content-Type": "application/json"
};

export function applyAuthHeaders() {
  const token = appStorage.get("token");
  return {
    ...defaultHeaders,
    Authorization: `Bearer ${token}`,
  };
}

export default axios.create({
  baseURL: config.get("BASE_URL"),
  headers: defaultHeaders,
});
