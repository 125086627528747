import { useForm } from "react-hook-form";
import { IHimbauan } from "../../models";
import React from "react";
import { Box, Button, Divider, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, Text, Textarea, useToast } from "@chakra-ui/react";
import { BackButton, Loading } from "../../components";
import { createHimbauanService } from "../../services";
import { FiSave } from "react-icons/fi";

export default function HimbauanPage() {
  const toast = useToast();
  const himbauanService = createHimbauanService();
  const [himbauanData, sethimbauanData] = React.useState<IHimbauan | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IHimbauan>();

  async function fetchHimbauan() {
    const result = await himbauanService.get();
    sethimbauanData(result.data);
  }

  async function onSubmit(values: Partial<IHimbauan>) {
    const result = await himbauanService.post(values);
    if (!result.error) fetchHimbauan();
    toast({
      title: result.error ? "Gagal" : "Berhasil",
      description: result.message,
      status: result.error ? "error" : "success",
      duration: 3000,
    });
  }

  React.useEffect(() => {
    fetchHimbauan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!himbauanData) return <Loading />;

  return (
    <Box>
      <Flex alignItems={"center"} gap={3} mb={6}>
        <BackButton />
        <Heading size={{ base: "sm", md: "md" }}>
          Himbauan
        </Heading>
      </Flex>
      <Stack spacing={3} w={{ base: "full", md: "lg" }} border={"1px"} borderColor={"teal.500"} p={4} rounded={"lg"}>
        <Flex
          flexDir={"column"}
          alignItems={"center"}
          textAlign={"center"}
          gap={2}>
          <Text fontWeight={"semibold"}>
            Perbarui kontak pengaduan dan himbauan
          </Text>
          <Text fontSize={"sm"}>
            Data dibawah ini merupakan data yang yang akan tampil pada halaman dashboard mahasiswa
          </Text>
        </Flex>
        <Divider my={3} />
        <FormControl isInvalid={!!errors.hp}>
          <FormLabel>No. HP pengaduan</FormLabel>
          <Input
            placeholder="No. HP (cth: 0812xxxxxxxx)"
            defaultValue={himbauanData.hp}
            {...register("hp", {
              pattern: /[0-9]/,
              required: "Nomor HP tidak boleh kosong",
            })} />
          <FormErrorMessage>{errors.hp?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>Email pengaduan</FormLabel>
          <Input
            defaultValue={himbauanData.email}
            {...register("email", {
              required: "Email tidak boleh kosong",
            })} />
          <FormErrorMessage>{errors.hp?.message}</FormErrorMessage>
        </FormControl>
        <Divider my={3} />
        <FormControl>
          <FormLabel>Judul himbauan <i>(opsional)</i></FormLabel>
          <Input
            defaultValue={himbauanData.judul}
            placeholder="Kosongkan jika tidak ada"
            {...register("judul")} />
        </FormControl>
        <FormControl>
          <FormLabel>Pesan himbauan <i>(opsional)</i></FormLabel>
          <Textarea
            defaultValue={himbauanData.deskripsi}
            placeholder="Kosongkan jika tidak ada"
            {...register("deskripsi")} />
        </FormControl>
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={isSubmitting}
          leftIcon={<FiSave />}
          onClick={handleSubmit(onSubmit)}>
          Simpan
        </Button>
      </Stack>
    </Box>
  );
}
